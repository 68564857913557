.responsive-image-small {
  width: 100%; /* Ensures full width on smaller screens */
  max-width: 30vw; /* Restricts width to 30% of the viewport on larger screens */
  height: auto; /* Maintains aspect ratio */
  display: block;
  margin: 0 auto; /* Centers the image */
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.responsive-image-medium {
  width: 50%; /* Ensures full width on smaller screens */
  max-width: 50vw; /* Restricts width to 90% of the viewport on larger screens */
  height: auto; /* Maintains aspect ratio */
  display: block;
  margin: 0 auto; /* Centers the image */
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.responsive-image-large {
  width: 90%; /* Ensures full width on smaller screens */
  max-width: 90vw; /* Restricts width to 90% of the viewport on larger screens */
  height: auto; /* Maintains aspect ratio */
  display: block;
  margin: 0 auto; /* Centers the image */
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .responsive-image-small {
    width: 75%;
    max-width: 100%; /* Overrides to full width for mobile */
  }
  .responsive-image-medium {
    width: 90%;
    max-width: 100%; /* Overrides to full width for mobile */
  }
  .responsive-image-large {
    width: 100%;
    max-width: 100%; /* Overrides to full width for mobile */
  }

  .left-column {
    order: 1;
  }
  .right-sidebar {
    order: 2; /* Ensure it appears after the main content */
  }

}

.custom-modal .modal-content {
  background-color: #2f2f2f;
  color: #ffffff;
  padding: 1em;
}

.modal-backdrop
{
    opacity:0.8 !important;
}

.custom-modal.light .modal-content {
  background-color: #fff;
}

.custom-modal.dark .modal-content {
  background-color: #1e1e1e;
}

.custom-modal.dark .modal-content button.btn-close {
  filter:invert(1);
}

.custom-modal .modal-content .modal-header.border-no {
  border: none;
}

.custom-modal.light .modal-content .modal-header {
  color: #2f2f2f;
}

.custom-modal .modal-content .modal-header .modal-title {
  font-size: 2rem;
  font-weight: 400;
  font-style: normal;
}

.custom-modal .modal-content .modal-header .btn-close {
  padding: 1rem;
}

.custom-modal .modal-content .modal-footer {
  border: none;
}

.custom-modal .modal-content .modal-btn {
  padding: 1em 2em;
  border-radius: 4px;
  border: 1px solid #3f51b5;
  background-color: #3f51b5;
}

.custom-modal .modal-content input.form-control {
  padding: 1em;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.custom-modal.light .modal-content input.form-control {
  border: 1px solid #2f2f2f;
  color: #2f2f2f;
}

.custom-modal.light .modal-content label {
  color: #2f2f2f;
}

.custom-modal .modal-content input.form-control::placeholder {
  color: #6e6e6e;
}

.custom-modal .modal-content input.form-control:focus {
  box-shadow: none;
  background: transparent;
  
}

.custom-modal.light .modal-content input.form-control:focus {
  border: 1px solid #2f2f2f;
}

.custom-modal.dark .modal-content input.form-control:focus {
  border: 1px solid #fff !important;
}

.canvas-default-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  width: 80%;
  max-width: 830px;
  border-radius: 25px;
}

.heading-common {
  color: #fff;
  text-align: center;
  font-style: normal;
  line-height: normal;
  /* font-family: 'Work Sans', sans-serif; */
  font-weight: 400;
  margin-bottom: 1rem;
  /* opacity: 0.5; */
}
.create-modal .sub-heading {
  font-size: 3rem;
  letter-spacing: 0.03rem;
}

.create-modal .heading {
  font-size: 0.875rem;
  letter-spacing: 0.009rem;
}
.input-search-wrapper {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 0.2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background: #2f2f2f;
  border-radius: 8px;
  padding: 5px;
  display: none;
}

.input-search-wrapper > div:first-child {
  flex: 1;
}

.custom-input input {
  color: #fff;
  border-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  padding: 12px 24px !important;
  background: transparent;
  width: 100%;
}

.custom-input input:focus {
  color: #fff !important;
}

.custom-input input::placeholder {
  color: #6e6e6e;
}

.custom-input input:focus {
  box-shadow: none;
  background: #0c0c0c;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.btn-gradient {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  border-radius: 8px;
  border: 1px solid #808080;
  background: linear-gradient(
    90deg,
    #3b2bee 0%,
    rgb(233, 130, 62) 100%
  ); /* max-width: 66px; */
  padding: 0.75rem 1rem;
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
}
.btn-gradient:hover {
  background: linear-gradient(
    90deg,
    #1B05F9 0%,
    #FF6600 100%
  );
}

.btn-action-wrapper {
  text-align: center;
}

.btn-wrapper {
  position: relative;
}

.point-svg {
  position: absolute;
  top: -11%;
  left: 57.8%;
  z-index: 9;
  display: none;
}

.button-group {
  margin-top: 2.75rem;
  border-radius: 4px;
  border: 1px solid #616161;
  background-color: #4a4a4a;
  gap: 23px;
}

.button-group img {
  display: inherit;
}

.btn-action {
  padding: 1rem;
  background-color: #4a4a4a;
  border: none;
  border-radius: 0;
}

#search-input-field {
  border-radius: 8px;
  border: 1px solid #3d3d3d;
  background: #151515;
  padding: 1rem;
  margin-top: 1rem;
}

/* Customize the scrollbar */
#search-input-field::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

/* Customize the scrollbar track */
#search-input-field::-webkit-scrollbar-track {
  background-color: transparent; /* Set the background color of the track */
}

/* Customize the scrollbar thumb (the draggable part) */
#search-input-field::-webkit-scrollbar-thumb {
  background-color: #3f3f3f; /* Set the color of the thumb */
  border-radius: 100px; /* Add rounded corners to the thumb */
  background-clip: padding-box;
}

#search-input-field .menu-ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

#search-input-field .menu-ul li {
  padding: 1rem 0rem;
  border-bottom: 1px solid #3f3f3f;
}

#search-input-field .menu-ul li:first-child {
  padding-top: 0px;
}

#search-input-field .menu-ul li a {
  color: #fff;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  opacity: 0.6;
  margin: 0px;
  padding: 0px;
  max-width: 378px;
  white-space: normal;
  word-break: normal;
  overflow-wrap: break-word;
  line-height: 1.3rem;
}

#search-input-field .menu-ul li a:focus,
#search-input-field .menu-ul li a:hover,
#search-input-field .menu-ul li a:active {
  text-decoration: none;
  background-color: transparent;
  opacity: 1 !important;
}

.description {
  font-size: 1.5rem;
  opacity: 0.5;
  display: none;
}

.hr-text {
  width: 100%;
  border-bottom: 1px solid #fff;
  color: #fff;
  line-height: 0.1em;
  margin: 2.5rem auto;
  max-width: 250px;
}

.hr-text span {
  background-color: rgb(21, 21, 21);
  padding: 0px 0.5rem;
}

.guided-tour {
  max-width: 22ch;
  margin: auto;
  line-height: calc(1em+0.5rem);
}
.guided-tour a {
  color: #fff;
  font-weight: bold;
}

.node-detail{
    display:flex;
    flex-direction:column;
    background:rgb(47 45 45);
    padding:12px;
    border-radius:4px;
    gap:10px;
    justify-content:center;
    align-items:center;
}
.description-div{
    border:1px solid gray;
    background:#181818;
    border-radius:6px;
    height:32px;
    padding:2px;
    color: white;
}
.description-div{
    border:1px solid gray;
    border-radius:6px;
}
.addNode-btn{
    border: 1px solid gray;
    background-color: gray;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 1px;
    padding-bottom: 1px;
    border-radius: 4px;
    color: white;
}
.des-type-div{
    display: flex;
    gap: 4px;
}
.res-header{
    background: rgba(18, 20, 31, 1);
    border-color: rgba(18, 20, 31, 1);
    color: white;
    opacity: 0.95;
    font-size: large;
    font-weight: bold;
}

.res-header .btn-close {
    filter: invert(1);
}

.res-body{
    height: fit-content;
    max-height: 700px;
    overflow-y: auto;
    background: rgba(18, 20, 31, 1);
    color: rgba(159, 161, 166, 1);
    border-color: rgba(18, 20, 31, 1);
}
.res-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.res-footer{
    background: rgba(18, 20, 31, 1);
    border-color: rgba(18, 20, 31, 1);
    opacity: 0.95;
    color: rgba(159, 161, 166, 1);
    font-style: italic;
    font-size: small;
}

.footer-button {
    color: white;
    border-color: rgba(63, 81, 181, 1);
    background-color: rgba(63, 81, 181, 1);
}

.footer-button:hover {
    border-color: rgba(63, 81, 181, 1);
    background-color: rgba(63, 81, 181, 1);
}

.cost-metrics-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .cost-metrics-table th,
  .cost-metrics-table td {
    border: 1px solid rgba(159, 161, 166, 1);
    padding: 8px;
    text-align: left;
  }
  
  .cost-metrics-table th {
    font-weight: bold;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0.75;
    color: white;
  }

  .cost-metrics-table td {
    background: rgba(18, 20, 31, 1);
    color: rgba(159, 161, 166, 1);
  }
.res-header{
    background: rgba(18, 20, 31, 1);
    border-color: rgba(18, 20, 31, 1);
    color: white;
    opacity: 0.95;
    font-size: large;
    font-weight: bold;
}

.res-header .btn-close {
    filter: invert(1);
}

.res-body{
    height: fit-content;
    max-height: 700px;
    overflow-y: auto;
    background: rgba(18, 20, 31, 1);
    color: rgba(159, 161, 166, 1);
    border-color: rgba(18, 20, 31, 1);
}
.res-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.res-footer{
    background: rgba(18, 20, 31, 1);
    border-color: rgba(18, 20, 31, 1);
    opacity: 0.95;
    color: rgba(159, 161, 166, 1);
    font-style: italic;
    font-size: small;
}

.footer-button {
    color: white;
    border-color: rgba(63, 81, 181, 1);
    background-color: rgba(63, 81, 181, 1);
}

.footer-button:hover {
    border-color: rgba(63, 81, 181, 1);
    background-color: rgba(63, 81, 181, 1);
}

.subtitle {
    color: rgba(159, 161, 166, 1);
}

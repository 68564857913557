.navbar-brand-wrapper {
    margin-left: 75px;
}

.menu-toggle {
    display: none;
    cursor: pointer;
}

.menu-icon {
    width: 30px;
    height: 30px;
}

.menu-content {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #ffffff;
    z-index: 1000;
    padding: 20px;
    overflow-y: auto;
    box-sizing: border-box;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
}

.menu-content.show {
    display: block;
    transform: translateX(0);
}

.menu-close {
    display: flex;
    justify-content: flex-end;
}

.cross-icon {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.nav-items {
    display: flex;
    margin-right: 80px;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.nav-link {
    font-size: 18px;
    text-decoration: none;
    color: black;
    transition: color 0.3s ease;
}

.nav-middle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.nav-item {
    font-size: 18px;
}

.beta-badge {
    background: #c735b3;
    padding: 4px 10px;
    border-radius: 4px;
    color: white;
    font-size: 14px;
    margin-left: 8px;
}

.btn {
    display: inline-block;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif;
    font-size: var(--size-2x);
    text-decoration: none;
    background-color: var(--clr-purple);
    color: #f1f5f9;
    padding: 16px 24px;
    border-radius: 4px;
    transition: transform 0.3s, box-shadow 0.2s;
    line-height: 28.8px;
    width: 200px;
    margin-bottom: 15px;
}

.chevron-icon {
  font-size: 12px; /* Adjust the size as needed */
  stroke-width: 1; /* Adjust the stroke width to make it thinner */
  margin-left: 5px;
  color: #3a3939; /* Light grey color */
}

@media (max-width: 768px) {
    .navbar-brand-wrapper {
      margin-left: 15px;
    }

    .nav-middle-container {
      display: none;
    }

    .menu-toggle {
      display: block;
    }

    .menu-content.show {
      display: block;
    }

    .nav-link {
      font-size: 16px;
    }

    .btn {
      display: none !important;
    }

    .menu-content {
      width: 374px;
      height: 374px;
      top: 10%;
    }
}

@media (max-width: 480px) {
    .navbar-brand-wrapper {
      margin-left: 10px;
    }

    .nav-link {
      font-size: 18px;
      padding-right: 120px;
    }

    .menu-content {
      width: 374px;
      height: 374px;
      top: 0;
      padding: 15px;
    }
}

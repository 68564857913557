.react-tour {
  color: #fff !important;
  border-radius: 4px !important;
  background: #202020 !important;
  top: 5.7rem !important;
  min-width: 330px !important;
  padding: 1rem !important;
}

.react-tour div[data-tour-elem="controls"] {
  gap: 0.5rem !important;
  justify-content: end;
}

.react-tour div[data-tour-elem="controls"] button {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.react-tour span[data-tour-elem="badge"] {
  background: none;
  margin-top: auto;
  box-shadow: none;
  bottom: 10px !important;
  right: -5rem;
  z-index: -1 !important;
}

.react-prev-next-btn {
  box-shadow: none;
  background: none;
  border: none;
  color: #fff;
  border-radius: 4px;
  background: #5a46ff;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.custom-close-button {
  position: absolute;
  bottom: 1rem;
  text-decoration: underline !important;
  font-weight: 600;
  z-index: 999 !important;
}

.point-svg {
  position: absolute;
  top: -6rem;
}

.point-svg svg {
  transform: rotate(90deg);
}

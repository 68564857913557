.App {
  text-align: center;
  background-color: white;
  color: black;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.react-flow__panel.react-flow__controls {
  pointer-events: all;
  display: grid;
  grid-template-columns: auto auto;
  margin: 18px;
}
.react-flow__controls-button{
  height: 14px !important ;
  width: 14px !important;
}

.react-flow__arrowhead polyline {
  stroke: #FFFFFF;
  stroke-width: 0.5px;

}

.react-flow__edge-textbg {
  stroke-width: 1px;
  stroke: #4E4E4E;
}

/* .page-break-before {
  page-break-before: always;
} */

/* .page-break-before::before {
  content: '';
  page-break-before: always;
  display: block;
} */

.owl-item:not(.active) {
  opacity: 0.5; /* Apply blur effect to non-active items */
}

.owl-item:nth-child(odd) {
  /* background: #fff0f5; */
  max-width: 808px;
  max-height: 507px;
}

.owl-item:nth-child(even) {
  /* background: #f0fffb; */
  max-width: 808px;
  max-height: 507px;
}

.owl-carousel .owl-stage-outer::before  {
  /* content: url('images/quote.svg'); */
  position: absolute;
  height: 80px;
  width:80px;
  top: 1.5%;
  left: 19.5%;
  z-index: 999;
  animation: fadeInOut 6s linear 4s infinite; 
}

@keyframes fadeInOut {
  0% { opacity: 1; }
  25% { opacity: 0; }
  50% { opacity: 0; }
  75% { opacity: 0.5; }
  100% { opacity: 1; }
}
@media (min-width: 375px) {
  .owl-carousel .owl-stage-outer::before  {
    height: 20px;
    width:20px;
    top: 0.5%;
    left: 0.5%;
  }
}
@media (min-width: 768px)  {
  .owl-carousel .owl-stage-outer::before  {
    left: 10.5%;
  }
}
@media (min-width: 1024px) {
  .owl-carousel .owl-stage-outer::before  {
    left: 12.5%;
  }
}
@media (min-width: 1280px) {
  .owl-carousel .owl-stage-outer::before  {
    left: 20%;
  }
}

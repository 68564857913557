.quote {
  position: relative;
  padding: 1em 1.5em;
  margin: 1.5em 0;
  font-style: italic;
  background: #f9f9f9;
  border-left: 10px solid #c66b6b;
}

.quote:before {
  content: open-quote;
  font-size: 4em;
  position: absolute;
  left: 10px;
  top: -10px;
  color: #ccc;
}

.quote:after {
  content: close-quote;
  font-size: 4em;
  position: absolute;
  right: 10px;
  bottom: -10px;
  color: #ccc;
}

.quote cite {
  display: block;
  margin-top: 1em;
  font-size: 0.9em;
  color: #555;
  text-align: right;
}
.custom-offcanvas {
  position: absolute !important;
  top: 4.2rem !important;
  left: 78px !important;
  z-index: 0 !important;
}
.left-cus-sidebar {
  background: #0c0c0c;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  z-index: 1 !important;
  position: relative;
  border-right: 0.5px solid #444;
}

.left-cus-sidebar a {
  border-radius: 0px !important;
  color: #fff;
  opacity: 0.5;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  color: #fff;
  position: relative;
  min-width: 100%;
}

.left-cus-sidebar-nav > div:first-child {
  display: flex;
}

.left-cus-sidebar-nav > div:first-child a {
  border-bottom: 1px solid #444;
}

.left-cus-sidebar-nav > div:first-child > button {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid #444;
  background: #2f2f2f;
}

.left-cus-sidebar a:focus,
.left-cus-sidebar a:hover {
  color: #fff !important;
  opacity: 1;
}

.left-cus-sidebar a.active::after {
  background-color: #d2d2d2;
}

.left-cus-sidebar.sidebar-open a.active {
  background: #2f2f2f;
  /* border-right: 1px solid #d2d2d2; */
  color: #fff !important;
  opacity: 1;
}

.left-cus-sidebar-nav > div {
  width: 100%;
}

.expand-wrapper {
  position: relative;
  z-index: 9 !important;
  display: flex;
  width: 100%;
  background: #2f2f2f;
  font-size: 14px;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
  transition: transform 0.3s ease;
  transition: display 0.3s ease;
}

.expand-des {
  height: 69px;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  /* border-left: 1px solid #444444;
    margin-left: 1.2rem; */
  border: 1px solid #444;
  border-left: none;
}
.expand-des .dropdown button {
  display: flex;
  align-items: center;
  /* flex-wrap: nowrap; */
}
.expand-des button {
  color: #8f8f8f;
}
.expand-des h6 {
  margin: 0;
}

.expand-close {
  border: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem 0.3rem;
  background-color: #2f2f2f;
  position: absolute;
  right: -5%;
  margin-top: auto;
  top: 40%;
  border-radius: 0 40px 40px 0;
  margin-bottom: auto;
}

.expand-btn {
  padding: 1.2rem 0.3rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: none;
  background: #2f2f2f;
  height: 50px;
  border-radius: 0 22px 22px 0;
  border: 1px solid rgba(47,47,47,1);
}

.sidebar-wrapper {
  height: 100%;
  width: 100%;
  background: #2f2f2f;
}

.sidebar-content {
  text-align: justify;
  max-height: 80vh;
  overflow: auto;
  padding: 1rem;
  color: #fff;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

.sidebar-content::-webkit-scrollbar {
  width: 2px;
  background-clip: content-box;
  padding: 10px;
}

.sidebar-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.sidebar-content::-webkit-scrollbar-thumb {
  background: #444;
  background-clip: content-box;
  border-radius: 100px;
}

.sidebar-search-container {
  background: #2f2f2f;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  width: 20rem;
  transform: translate3d(-50%, -17%, 0px) !important;
}

.sidebar-search {
  border-bottom: 1px solid #444;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.sidebar-search-input {
  max-width: 8rem;
  color: #939393 !important;
  border: none;
  background-color: transparent;
  outline: none;
}

.sidebar-search-input::placeholder {
  color: #939393;
}

.sidebar-search-input:focus {
  background: none;
  outline: none;
  box-shadow: none;
}

.search-result-dropdown button {
  padding: 6px;
}

.search-result-dropdown button:hover {
  background: #5a46ff !important;
}

.search-result-dropdown-result {
  background-color: #1b1b1b;
  padding: 1rem;
  width: 12rem;
}

.search-result-dropdown-result a {
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  font-size: 14px;
  padding: 0px;
}

.search-result-dropdown-result a:hover {
  background: transparent;
  color: #fff;
}

.search-result-dropdown-result a div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.search-result-dropdown-result svg {
  width: 25px;
}

.custom-hr {
  border-top: 1px solid#444;
  margin: 0.5rem 0;
}

.markup-navigation-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.markup-navigation-wrapper button,
.markup-navigation-wrapper a {
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  padding: 12px 16px;
  border: 1px solid #fff;
}


.text-pros{
  display: flex;
}

.text-node-hightlight{
  margin-left: 2px;
  margin-right: 2px;
}
.analysisTab{
  padding: 12px;
}
.act-log-btn {
  top: 4px;
}
.pros-cons {
  border-bottom: 1px solid rgba(61,61,61,1);
}
.text-cons{
  display: flex;
  gap: 15px;
}
.cons-item{
   display: flex;
   flex-wrap: wrap;
   width: 300px;
}
.cons-askai-btn{
  margin-top: 4px;
  cursor: pointer;
  border: 1px solid rgba(128,128,128,1);
  background: linear-gradient(90deg, #1b05f9 0%, #f60 100%);
  border-radius: 4px;
  width: 30px !important;
  height: 20px;
  text-align: center;
}
.cons-askai-btn:hover{
  border: 1px solid white;
}
.side-panel-modal-overlay {
    position: fixed;
    top: 0;
    left: 10%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0s linear 0.3s;
  }
  .side-panel-modal.open {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;

  }
  .side-panel-modal {
    position: fixed;
    top: 0;
    right: -300px; /* Start with the modal outside of the screen */
    width: 300px;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
    z-index: 10000;
    padding: 20px;
    transition: right 0.3s ease;
    background: #2f2f2f;
    color: #fff;
  }
  .side-panel-modal.open {
    right: 0; /* Slide the modal in from the right */
  }

  .side-panel-modal h1 {
    font-size: 1.5rem;
  }
  .close-button {
    font-size: 12px;
    border: none;
    background: none;
    cursor: pointer;
  }

main {
  background-color: #1e1e1e !important;
  max-height: max-content;
  height: 100%;
  overflow-y: auto;
}

.logo-icon {
  max-width:45px;
}

.logo-icon path {
  fill: #fff;
}

.container {
  padding-top: 0px;
  height: 100%;
  /* height: auto; */
}
.container .row {
  width: 100%;
  /* align-content: flex-start; */
  flex-grow: 1;
}
.right-side {
  background-color: #2f2f2f;
  border-radius: 5px;
  padding: 32px;
  height: 100%;
}
nav.navbar div.navbar-brand img {
  max-width: 165px;
}
.user-img {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  border: 2px solid #5A46FF;
  padding: 8px;
}
nav.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
nav.navbar .navbar-collapse {
  flex-grow: unset;
}

nav.navbar .navbar-collapse .navbar-nav {
  align-items: center;
}

nav.navbar .navbar-collapse div.navbar-nav a {
  color: #6e6e6e;
  transition: all.3s ease-in-out;
  padding-right: 16px;
  padding-left: 26px;
}
nav.navbar div.navbar-nav a:hover {
  color: #5A46FF;
}

nav.navbar div.navbar-nav a.project-link {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 16px 32px;
  margin: 0 16px 0 16px;
  display: inline-block;
  transition: all.3s ease-in-out;
}
nav.navbar div.navbar-nav a.project-link img {
  margin-right: 16px;
}
nav.navbar div.navbar-nav a.project-link:hover {
  color: #5A46FF;
  border-color: #5A46FF;
}

nav.navbar div.navbar-nav a.project-link span {
  margin-left: 5px;
}
.has-search {
  border: 1px solid #fff;
  border-radius: 3px;
  min-height: 54px;
  padding: 16px 32px;
  position: relative;
}

.has-search span {
  position: absolute;
  left: 32px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}

.has-search span i {
  color: #6e6e6e;
  font-size: 18px;
}
.has-search .form-control {
  background: transparent;
  border: none;
  outline: none;
  padding: 0px 0px 0px 30px;
}
.has-search .form-control::placeholder {
  color: #6e6e6e;
}
.has-search .form-control {
  box-shadow: none;
  border: none;
}

.has-search input {
  color: #fff !important;
  border: none;
}
.left-side .nav .nav-item {
  display: block;
  width: 100%;
}
.left-side .nav .nav-item a {
  color: #fff;
  font-size: 18px;
  padding: 16px 32px;
  text-align: left;
}
.left-side .nav a.active {
  background: #2b2e3f !important;
  border-radius: 4px !important;
}
.left-side .nav a span {
  margin-right: 16px;
}
.left-side .blue-btn {
  width: 100%;
}

.blue-btn {
  background-color: #5A46FF;
  border: 1px solid #5A46FF;
  display: inline-block;
  text-align: center;
  color: #fff;
  padding: 16px 32px;
  border-radius: 3px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.blue-btn span {
  margin-right: 16px;
}
.blue-btn:not(.btn-disabled):hover {
  background-color: #fff;
  color: #5A46FF;
}
.btn-disabled {
  background: #848297;
  border: 1px solid #848297;
  user-select: none;
  cursor:default !important;
}

.right-side .blue-btn {
  margin: 0 auto;
  width: fit-content;
  display: block;
}

.heading1 {
  color: #fff;
  font-size: 4rem;
  margin-bottom: 46px;
  font-size: 400;
  letter-spacing: 0.05em;
}

.heading6 {
  color: #fff;
  font-family: 'DM Sans', sans-serif;
  font-size: 1.1rem;
  margin-bottom: 16px;
}

.font-700 {
  font-weight: 700;
}

.opacity-half {
  opacity: 0.5;
}

.main-heading {
  margin-bottom: 42px;
}

div.navbar-nav .nav-link span {
  margin-right: 16px;
  margin-top: 5px;
}

label.form-label {
  font-weight: 700;
  font-style: normal;
}

.patch-dropdown-menu {
  background: #444;
}

.patch-dropdown-menu a {
  color: #fff !important;
  opacity: 0.6;
}

.patch-dropdown-menu a:hover {
  background: none !important;
  opacity: 1;
}

.patch-dropdown button,
.patch-dropdown button:hover,
.patch-dropdown button:active,
.patch-dropdown button:focus {
  background: none !important;
  border: none !important;
}

.dropdown-bg {
  border-radius: 2px;
  background: #444;
  box-shadow: 0px 1px 4px 0px #101010 inset;
}

.patch-dropdown button::after {
  display: none !important;
}

.patch-dropdown button::before {
  display: none !important;
}

.patch-dropdown > span {
  margin-right: 0.5rem;
}

.patch-dropdown div {
  z-index: 1050;
}

.user-dropdown-toggle {
  height: auto;
  background-color: #444;
}

.user-dropdown-toggle a {
  color: #fff !important;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  opacity: 0.6;
  margin: 0px;
  padding: 0px;
  max-width: 378px;
  white-space: normal;
  word-break: normal;
  overflow-wrap: break-word;
  line-height: 1.3rem;
}

.user-dropdown-toggle a:hover {
background: none !important;
opacity: 1;
}

/*Extra Small Devices*/
@media screen and (max-width: 576px) {
  .heading1 {
    font-size: 1.5em;
    margin-bottom: 30px;
  }

  .heading6 {
    font-size: 1em;
    margin-bottom: 8px;
  }
}

/*Small Devices*/
@media screen and (min-width: 576px) {
  .heading1 {
    font-size: 1.5em;
    margin-bottom: 30px;
  }

  .heading6 {
    font-size: 1em;
    margin-bottom: 8px;
  }
}

/*Medium Devices*/
@media screen and (min-width: 768px) {
  .heading1 {
    font-size: 2.25em;
    margin-bottom: 30px;
  }
}

/*Large Devices*/
@media screen and (min-width: 992px) {
 
}

/*Extra Large Devices*/
@media screen and (min-width: 1200px) {
  .heading1 {
    font-size: 4em;
    margin-bottom: 30px;
  }

  .heading6 {
    font-size: 1.125em;
    margin-bottom: 8px;
  }
}
.shortcut-key-table{
  text-align: left;
  width: 280px;
  margin: auto;
}
.shortcut-heading{
  color: white;
  padding: 4px;
  font-size: 18px;
}
.shortcut-data-row{
  border-bottom: 1px solid gray;
}
.shortcut-key-column{
  color: white;
  padding: 4px;
}

#res-pop-up .popover-arrow {
    border-bottom: 7px solid rgb(18,31,20);
    border-left: 9px solid transparent;
}
.resource-icon-popUp {
    display: flex;
    justify-content: space-between;
}
.res-i-pop-up {
    display: flex;
    flex-direction: column;
    padding: 4px;
}
.res-url {
    color: #0078D4;
}
.res-detail {
    border-bottom: 1px solid gray;
    padding: 4px;
    font-family: 'Work Sans', sans-serif;
}
.res-detail:last-child {
    border-bottom: none;
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: break-all;
}
.res-link {
    padding: 4px;
    color: white;
    text-align: left;
    font-family: 'Work Sans', sans-serif;
}
.res-link:hover {
    color: rgba(179, 170, 255, 1);
}

:root {
  --clr-purple: #5a46ff;

  /* sizes */
  --size-xxs: 0.5rem;
  --size-xs: 0.75rem;
  --size-sm: 0.875rem;
  --size-base: 1rem;
  --size-lg: 1.125rem;
  --size-xl: 1.25rem;
  --size-2xl: 1.5rem;
  --size-3xl: 1.875rem;
  --size-4xl: 2.25rem;
  --size-5xl: 3rem;
  --size-6xl: 3.75rem;
  --size-7xl: 4.5rem;
  --size-8xl: 6rem;
  --size-9xl: 8rem;
  --size-10xl: 10rem;
}
a html {
  scroll-behavior: smooth;
}

button {
  display: inline-block;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  color: inherit;
}

a {
  color: var(--clr-rose);
}

strong {
  color: var(--clr-indigo);
}

/*landing classes start*/
/*========================================================*/

.navbar-container {
  background-color: #fff;
}

.bg-light-grayish-blue {
  background-color: #e9edf5;
  font-family: 'Work Sans', sans-serif;
  overflow-x: hidden;
}

.bg-dark-blue {
  background-color: #dde3f1;
}

.cloud-left-section {
  position: relative;
  box-sizing: border-box;
  /* padding-top: 9.6875rem;
    padding-bottom: 2.75rem; */
  padding-top: 3rem;
  padding-bottom: 2rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
}

.cloud-left-section h1 {
  padding: 0 !important;
  margin: 0 !important;
  max-width: 16ch;
  color: #0c0c0c;
  font-family: 'Work Sans', sans-serif;
  /* font-size: 64px; */
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: left;
}

.cloud-left-section p {
  padding: 0 !important;
  margin: 0 !important;
  max-width: 40ch;
  color: #0c0c0c;
  font-family: 'Work Sans', sans-serif;
  /* font-size: 20px; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
}

.cloud-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
}

.modal-title {
  background-color: red !important;
}

.request-access-container .request-access-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: var(--size-lg);
}

.btn-close {
  color: #000 !important;
  border: 1px solid #000 !important;
  background: #fff !important;
}

/*========================================================*/
/*landing classes end*/

/*utils classes start*/
/*========================================================*/

.notificationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #DAD6FF;
  height: 54.21px;
  font-size: 16px;
  padding: 0 20px;
}

.notificationContainer span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trophyImage {
  margin-right: 10px;
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  right: 2%;
}

.closeIcon img {
  width: 20px;
  height: auto;
}

@media (max-width: 768px) {
  .notificationContainer {
    font-size: 14px;
    height: 45px;
    padding: 0 15px;
  }

  .trophyImage {
    margin-right: 8px;
  }

  .closeIcon img {
    width: 18px;
  }
}

@media (max-width: 480px) {
  .notificationContainer {
    font-size: 12px;
    height: 40px;
    padding: 0 10px;
  }

  .trophyImage {
    margin-right: 6px;
  }

  .closeIcon img {
    width: 16px;
  }
}

.btn {
  display: inline-block;
  font-weight: 600;
  font-family: 'Work Sans', sans-serif;
  font-size: var(--size-2xl);
  text-decoration: none;
  letter-spacing: normal;
  background-color: var(--clr-purple);
  color: #f1f5f9;
  padding: 16px 24px 16px 24px;
  border-radius: 4px;
  transition: transform 0.3s, box-shadow 0.2s;
  Line-height: 28.8px;
  width: 200px;
  margin-bottom: 15px;
}

.beta-badge {
  background: #c735b3;
  padding: 5px 9px;
  border-radius: 4px;
  color: white;
  position: absolute;
  font-size: 14px;
}

.btn-small {
  display: inline-block;
  font-weight: 500;
  font-family: 'Work Sans', sans-serif;
  font-size: var(--size-base);
  text-decoration: none;
  letter-spacing: normal;
  background-color: var(--clr-purple);
  color: #f1f5f9;
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  transition: transform 0.3s, box-shadow 0.2s;
  Line-height: 18.8px;
  width: 150px;
  margin-bottom: 15px;
}

.cloud_flex_direction h1{
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  line-height: 4.8rem;
}
.btn:hover {
  transform: translateY(-3px);
}

.vision-animate-wrapper {
  top: 0%;
  left: -17%;
}

.vision-animate {
  width: 8rem;
}

.think-section {
  display: none;
  /* background: linear-gradient(to right, #fff 50%, #e9edf5 50%); */
  background-image: linear-gradient(#5a46ff, #5a46ff);
  background-size: 1px 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.community-heading {
  text-align: left;
  font-family: 'Sue Ellen Francisco', sans-serif;
  font-size: 48px;
  font-weight: 400;
}

.arrow-container {
  display: flex;
  justify-content: center;
  padding-left: 43%;
}

.arrow-img {
  padding-right: 100px;
  margin-top: -35px;
  transform: scale(0.75);
  width: 7%;
  height: 5%;
}

.mobileView {
  display: none;
}

@media (max-width: 768px) {
  .community-heading {
    margin-left: 4rem;
    text-align: left;
    font-size: 32px;
  }

  .arrow-container {
    padding-left: 0;
  }

  .arrow-img {
    padding-right: 100px;
    margin-top: -20px;
    transform: scale(0.5);
    width: 30%;
    height: auto;
  }

  .hidden-mobile {
    display: none;
  }

  .mobileView {
    display: block;
    margin-top: 50px;
  }

  .beta-badge {
    line-height: normal;
    font-size: 12px;
    right: 0;
    top: 0;
  }
}

@media (max-width: 480px) {
  .btn {
    font-size: var(--size-lg);
    padding: 10px 16px;
    width: 90%;
    border-radius: 8px;
  }
  .community-heading {
    text-align: left;
    font-size: 28px;
  }
  .arrow-container {
    margin-left: 232px;
    transform: rotate(60deg);
    width: auto;
  }
  .arrow-img {
    padding-right: 1px;
    transform: scale(1);
    width: 40%;
    height: auto;
  }
  .beta-badge {
    line-height: normal;
    font-size: 12px;
    right: 0;
    top: 0;
  }
}

@media (min-width: 1024px) {
  .community-heading {
    margin-left: 6rem;
    text-align: left;
    font-size: 28px;
  }

  .arrow-container {
    padding-left: 37.5%;
  }

  .arrow-img {
    padding-right: 250px;
    margin-top: -30px;
    transform: scale(0.65);
    width: 30%;
    height: auto;
  }
}

@media (min-width: 1280px) {
  .community-heading {
    text-align: center;
    font-size: 48px;
  }

  .arrow-img {
    padding-right: 1px;
    transform: scale(0.9);
    width: 11%;
    height: 5%;
  }
}

.think-left-title {
  flex: 1;
}

.think-left-title p {
  width: fit-content;
}

.think-right-title {
  flex: 1;
  /* font-size: var(--size-2xl); */
}

.think-right-title p {
  width: fit-content;
}

.think-img-wrapper {
  margin-top: 3.5rem;
  margin-bottom: 9rem;
  /* padding-right: 2.5rem; */
}

.think-img {
  width: 54%;
}

.custom-input {
  border-radius: 0px !important;
  border: none !important;
  border-bottom: 1px solid #e4e4e4 !important;
  font-family: 'Work Sans', sans-serif !important;
  box-shadow: none !important;
  padding: 10px !important;
  font-size: var(--size-lg) !important;
}

.custom-input::placeholder {
  color: #6e6e6e !important;
  font-size: var(--size-lg) !important;
  opacity: 0.6;
}

.error {
  border-bottom: 1px solid #e61e1e !important;
}

.error-text {
  color: #e61e1e;
  padding-top: 10px;
}

/* /////////////////////////// */

.cloud_flex_direction {
  display: flex;
  /* flex-direction: row; */
  /* flex-direction: column-reverse; */
  text-align: center;
  overflow: hidden;
}
.updown_animation {
  margin-bottom: 278px;
}

.blue-btn-svg-one {
  animation: BlueMoveUpDownOne 10s linear infinite;
  width: 100x;
  top: -229px;
  left: 29%;
}

.blue-btn-svg-two {
  animation: BlueMoveUpDownTwo 10s linear infinite;
  width: 100x;
  top: -229px;
  left: 29%;
}

.blue-btn-svg-three {
  animation: BlueMoveUpDownThree 10s linear infinite;
  width: 100x;
  top: -229px;
  left: 29%;
}
.blue-btn-svg-three svg {
  animation: BrownMoveUpDownThreesvg 10s linear infinite;
}  
.purple-btn-svg-one {
  animation: PurpleMoveUpDownOne 12s linear infinite;
  top: -227px;
  left: 28%;
}

.purple-btn-svg-two {
  animation: PurpleMoveUpDownTwo 12s linear infinite;
  top: -227px;
  left: 28%;
}

.purple-btn-svg-three {
  animation: PurpleMoveUpDownThree 12s linear infinite;
  top: -227px;
  left: 28%;
}
.purple-btn-svg-three svg {
  animation: BrownMoveUpDownThreesvg 10s linear infinite;
}  
.green-btn-svg-one {
  animation: GreenMoveUpDownOne 12s linear infinite;
  top: -219px;
  left: 29%;
}

.green-btn-svg-two {
  animation: GreenMoveUpDownTwo 12s linear infinite;
  top: -219px;
  left: 29%;
}

.green-btn-svg-three {
  animation: GreenMoveUpDownThree 12s linear infinite;
  top: -219px;
  left: 29%;
}
.green-btn-svg-three svg {
  animation: BrownMoveUpDownThreesvg 10s linear infinite;
}  
.brown-btn-svg-one {
  animation: BrownMoveUpDownOne 10s linear infinite;
  top: -240px;
  left: 27%;
}

.brown-btn-svg-two {
  animation: BrownMoveUpDownTwo 10s linear infinite;
  top: -240px;
  left: 27%;
}

.brown-btn-svg-three {
  animation: BrownMoveUpDownThree 10s linear infinite;
  top: -240px;
  left: 28%;
}
.brown-btn-svg-three svg {
  animation: BrownMoveUpDownThreesvg 10s linear infinite;
} 

/* /////////////////////////// */

.set-wrapper svg {
  max-width: 40%;
  max-height: 40%;
}

.col-six-right {
  align-self: stretch;
  flex: 1;
  position: relative;
}

.btn-svg svg {
  max-width: 30%;
  max-height: 30%;
}

.absolute-one {
  top: -78px;
  left: -111px;
}
.absolute-two {
  top: -114px;
  right: -135px;
}
.absolute-three {
  top: 16px;
  left: -137px;
}
.absolute-four {
  top: -15px;
  right: -66px;
}
.intelligence-section {
  background-color: #07039a;
  padding: 3rem 1.75rem;
}

.intelligence-section > div:first-child {
  background: radial-gradient(
      44.13% 44.91% at 46.35% 57.72%,
      #000 7.93%,
      rgba(0, 0, 0, 0.92) 55.23%,
      rgba(0, 0, 0, 0.22) 81.21%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      108.88% 112.14% at 94.93% 6.64%,
      rgba(145, 168, 186, 0) 0%,
      rgba(59, 53, 107, 0.4) 30.04%,
      #201a53 100%
    ),
    #5a46ff;
  background-blend-mode: soft-light, hard-light, normal;
}

.vision-section {
  background-color: #2A1D9B;
  padding: 0 6rem;
}

.vision-title {
  margin-top: 2rem;
  font-family: 'Work Sans', sans-serif;
  font-size: 48px;
  color: #FFFFFF;
  text-align: left;
}

.vision-detail {
  max-width: 40ch;
  font-size: 28px;
  margin-top: 24px;

}

.vision-more-detail {
  max-width: 40ch;
  font-size: 20px;
  margin-top: 8px;

}

@media (max-width: 1024px) {
  .vision-section {
    padding: 0 4rem;
  }

  .vision-title {
    font-size: 32px;
    text-align: center !important;
  }

  .vision-detail {
    max-width: 35ch !important;
    text-align: center !important;
  }

  .vision-more-detail {
    max-width: 35ch !important;
    text-align: center !important;
  }
}

@media (max-width: 768px) {
  .vision-section {
    padding: 0 2rem;
  }

  .vision-title {
    font-size: 28px;
  }

  .vision-detail {
    max-width: 40ch;
    padding: 0 1rem;
    text-align: center !important;
  }

  .vision-more-detail {
    max-width: 40ch;
    padding: 0 1rem;
    text-align: center !important;
  }
}

@media (max-width: 480px) {
  .vision-section {
    padding: 0 1rem !important;
  }

  .vision-title {
    font-size: 24px !important;
    text-align: center !important;
  }

  .vision-detail {
    max-width: 95%;
    padding: 0;
    text-align: center !important;
    font-size: 1.35rem !important;
  }

  .vision-more-detail {
    max-width: 95%;
    padding: 0;
    text-align: center !important;
    font-size: 1.35rem !important;
  }
}

.puzzle-title {
  margin-top: 2rem;
  font-size: 38px;
}

@media (max-width: 480px) {
  .puzzle-title {
    margin-top: 2rem;
    font-size: 28px;
  }  
}

.banner_image_class {
  width: 100%;
  height: 100%;
}

.testimonials-section {
  background-color: #fff;
}

.testimonials-section .testimonials-heading {
  display: none;
}

.question-section .question-heading {
  margin-top: 3rem;
}

.question-section .question-heading-desk {
  display: none;
}

.question-section .question-section-right {
  display: none;
}

.question-section .question-section-left .mobile-hexagon .position-top{
  position: absolute;
  bottom: 100%;
  left:0;
  right:0;
  margin-left: auto;
  margin-right: auto;

}

.question-section .question-section-left .mobile-hexagon .position-bottom{
  position: absolute;
  top: 100%;
  left:0;
  right:0;
  margin-left: auto;
  margin-right: auto;
 }

.question-section .question-section-left .mobile-hexagon {
  margin-top: 10.5rem;
  margin-bottom: 10.5rem;
  /* border: 1px solid red; */
}

.question-animate-one {
  opacity: 1;
  animation: QuestionAnimateOne 12s linear infinite;
}

.question-animate-two {
  opacity: 0;
  animation: QuestionAnimateTwo 12s linear infinite;
}

.question-animate-three {
  opacity: 0;
  animation: QuestionAnimateThree 12s linear infinite;
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered-text p {
  font-size: 1rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1.6px;
  background: linear-gradient(
    90deg,
    #03f6f4 50%,
    rgba(255, 255, 255, 0.1) 50%
  );
  background-size: 200% auto;
  background-position: -50%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  transition: all 0.3s linear;
  animation: shine 6s infinite ease-in-out;
  opacity: 0.7;
}

.polygon-box {
  overflow: hidden;
  width: 250px;
  height: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flt_svg {
  visibility: hidden; 
  position: absolute;
  width: 0px;
  height: 0px;
}

.polygon {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  /* aspect-ratio: 1; */
  /* clip-path: polygon(
    92% 74%,
    50% 98%,
    8% 74%,
    8% 26%,
    50% 2%,
    92% 26%,
    92% 74%,
    90% 27%,
    50% 4%,
    10% 27%,
    10% 73%,
    50% 96%,
    90% 73%,
    90% 27%
  ); */
  clip-path: polygon(0 125.78px,40px 40px,125.78px 0,calc(100% - 125.78px) 0,calc(100% - 40px) 40px,100% 125.78px,100% calc(100% - 125.78px),calc(100% - 40px) calc(100% - 40px),calc(100% - 125.78px) 100%,125.78px 100%,40px calc(100% - 40px),0 calc(100% - 125.78px),0 125.78px,2px  calc(125.78px + 0.44px),2px calc(100% - 125.78px - 0.44px),calc(40px + 1.50px) calc(100% - 40px - 1.50px),calc(125.78px + 0.44px) calc(100% - 2px),calc(100% - 125.78px - 0.44px) calc(100% - 2px),calc(100% - 40px - 1.50px) calc(100% - 40px - 1.50px),calc(100% - 2px) calc(100% - 125.78px - 0.44px),calc(100% - 2px) calc(125.78px + 0.44px),calc(100% - 40px - 1.50px) calc(40px + 1.50px),calc(100% - 125.78px - 0.44px) 2px,calc(125.78px + 0.44px) 2px,calc(40px + 1.50px) calc(40px + 1.50px),2px calc(125.78px + 0.44px));


  display: grid;
}

.polygon1 {
  transform: rotate(0deg);
}

.polygon2 {
  transform: rotate(10deg);
}

.polygon3 {
  transform: rotate(15deg);
}

.polygon4 {
  transform: rotate(20deg);
}

.polygon:before { 
  content: "";
  animation: r 3s linear infinite;
}

.polygon1:before {
  /* background-color: red; */
  background: conic-gradient(transparent 80%, #6980ff 100%, #000);
}

.polygon2:before {
  background: conic-gradient(transparent 80%, #f142f5 100%, #000);
}

.polygon3:before {
  background: conic-gradient(transparent 80%, #3be862 100%, #000);
}

.polygon4:before {
  background: conic-gradient(transparent 80%, #3be862 100%, #000);
}

@keyframes r {
  to {
    rotate: 1turn;
  }
}

@keyframes shine {
  0%,
  100% {
    background-position: 60%;
  }

  10% {
    background-position: 60%;
  }

  20% {
    background-position: 60%;
  }

  30% {
    background-position: 160%;
  }

  40% {
    background-position: 160%;
  }

  50% {
    background-position: 160%;
  }

  60% {
    background-position: 60%;
  }

  70% {
    background-position: 60%;
  }

  80% {
    background-position: 60%;
  }

}

.mob-top-ques-one {
  animation: MobQuesOne 12s ease-in-out infinite;
}

.mob-top-ques-two {
  animation: MobQuesTwo 12s ease-in-out infinite;
}

.mob-top-ques-three {
  animation: MobQuesThree 12s ease-in-out infinite;
}

.mob-top-ques-four {
  animation: MobQuesFour 12s ease-in-out infinite;
}

@keyframes MobQuesOne {
  0%,100%{
    opacity: 1;
  }

  15% {
     opacity: 1;
  }

  30% {
      opacity: 0;
  }

  45% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }
}

@keyframes MobQuesTwo {
  0%,100%{
    opacity: 0;
  }

  15% {
     opacity: 0;
  }

  30% {
      opacity: 1;
  }

  45% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }
}

@keyframes MobQuesThree {
  0%{
    opacity: 0;
  }

  15% {
     opacity: 0;
  }

  30% {
      opacity: 0;
  }

  45% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  90% {
    opacity: 0;
  }

}

@keyframes MobQuesFour {
  0%,100%{
    opacity: 0;
  }

  15% {
     opacity: 0;
  }

  30% {
      opacity: 0;
  }

  45% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes QuestionAnimateOne {
  0% {
    opacity: 1;
  }

  10% {
    opacity: 1;
  }

  20% {
    opacity: 0.5;
  }

  30% {
    opacity: 0.5;
  }

  40% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes QuestionAnimateTwo {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  60% {
    opacity: 0.5;
  }

  70% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes QuestionAnimateThree {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  80% {
    opacity: 0.5;
  }

  90% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

@keyframes BlueMoveUpDownOne {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
    opacity: 0;
  }
}

@keyframes BlueMoveUpDownTwo {
  0%,
  100% {
    opacity: 0;
    transform: translateY(5px);
  }

  50% {
    transform: translateY(-10px);
    opacity: 1;
  }
}

@keyframes BlueMoveUpDownThree {
  0%,
  100% {
    opacity: 0;
    transform: translateY(5px);
  }

  50% {
    transform: translateY(-10px);
    opacity: 1;
  }
}

@keyframes PurpleMoveUpDownOne {
  0%,
  100% {
    transform: translateY(5px);
    opacity: 1;
  }
  50% {
    transform: translateY(-10px);
    opacity: 0;
  }
}

@keyframes PurpleMoveUpDownTwo {
  0%,
  100% {
    opacity: 0;
    transform: translateY(5px);
  }
  50% {
    opacity: 1;
    transform: translateY(-10px);
  }
}

@keyframes PurpleMoveUpDownThree {
  0%,
  100% {
    transform: translateY(5px);
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(-10px);
  }
}

@keyframes GreenMoveUpDownOne {
  0%,
  100% {
    transform: translateY(-5px);
    opacity: 1;
  }
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@keyframes GreenMoveUpDownTwo {
  0%,
  100% {
    opacity: 0;
    transform: translateY(-5px);
  }
  50% {
    opacity: 1;
    transform: translateY(-20px);
  }
}

@keyframes GreenMoveUpDownThree {
  0%,
  100% {
    transform: translateY(-5px);
    opacity: 0.3;
  }
  50% {
    opacity: 1;
    transform: translateY(-20px);
  }
}

@keyframes BrownMoveUpDownOne {
  0%,
  100% {
    transform: translateY(0px);
    opacity: 0;
  }
  50% {
    transform: translateY(20px);
    opacity: 1;
  }
}

@keyframes BrownMoveUpDownTwo {
  0%,
  100% {
    opacity: 1;
    transform: translateY(0px);
  }

  50% {
    transform: translateY(20px);
    opacity: 0;
  }
}

@keyframes BrownMoveUpDownThree {
  0%,
  100% {
    opacity: 1;
    transform: translateY(0px);
  }

  50% {
    transform: translateY(20px);
    opacity: 0;
  }
}
@keyframes BrownMoveUpDownThreesvg {
  0%,
  100% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }

  25% {
    opacity: 1;
  }
}

/* ////////////////////////////////// */

@media (min-width: 375px) {
  .set-wrapper svg {
    max-width: 40%;
    max-height: 40%;
  }

  .updown_animation {
    margin-bottom: 340px;
  }

  .col-six-right {
    align-self: stretch;
    flex: 1;
    position: relative;
  }

  .btn-svg svg {
    max-width: 40%;
    max-height: 40%;
  }

  .absolute-one {
    top: -28px;
    left: -20%;
  }

  .absolute-two {
    top: -55px;
    right: -20%;
  }

  .absolute-three {
    top: 103px;
    left: -25%;
  }

  .absolute-four {
    top: 46px;
    right: -15%;
  }
  .cloud_flex_direction h1{
    line-height:2.8rem;
  }
}

/*xs*/
@media (min-width: 475px) {
  .absolute-one {
    top: -28px;
    left: -14%;
  }

  .absolute-two {
    top: -81px;
    right: -16%;
  }

  .absolute-three {
    top: 116px;
    left: -23%;
  }

  .absolute-four {
    top: 46px;
    right: 0%;
  }

  .think-img-wrapper {
    margin-top: 6.5rem;
    margin-bottom: 13rem;
    /* padding-right: 2.5rem; */
  }

  .think-img {
    width: 56%;
  }
  .request-access-container .request-access-group {
    margin-top: var(--size-l);
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .request-access-container .request-access-group .request-access-input {
    flex-basis: 40%;
  }

  .request-access-container .request-access-group {
    font-size: var(--size-sm);
    padding: 0.5rem 1rem;
  }

  .request-access-btn {
    font-size: var(--size-sm);
    padding: 0.5rem 1rem;
    width: 100%;
  }
  .cloud_flex_direction h1{
    line-height:2.8rem;
  }
}
/* aboobe xs but blelow sm(>=500px) */
@media (min-width: 520px) {
  .set-wrapper svg {
    max-width: 45%;
    max-height: 45%;
  }

  .btn-svg svg {
    max-width: 42%;
    max-height: 42%;
  }

  .col-six-right {
    align-self: stretch;
    flex: 1;
    position: relative;
  }
  .absolute-one {
    top: -28px;
    left: -10%;
  }

  .absolute-two {
    top: -81px;
    right: 0%;
  }

  .absolute-three {
    top: 116px;
    left: -15%;
  }

  .absolute-four {
    top: 63px;
    right: 5%;
  }

  .blue-btn-svg-one {
    animation: BlueMoveUpDownOne 10s linear infinite;
    width: 100x;
    top: -220px;
    left: 29%;
  }

  .blue-btn-svg-two {
    animation: BlueMoveUpDownTwo 10s linear infinite;
    width: 100x;
    top: -220px;
    left: 29%;
  }

  .blue-btn-svg-three {
    animation: BlueMoveUpDownThree 10s linear infinite;
    width: 100x;
    top: -220px;
    left: 29%;
  }

  .purple-btn-svg-one {
    animation: PurpleMoveUpDownOne 12s linear infinite;
    top: -220px;
    left: 29%;
  }

  .purple-btn-svg-two {
    animation: PurpleMoveUpDownTwo 12s linear infinite;
    top: -220px;
    left: 29%;
  }

  .purple-btn-svg-three {
    animation: PurpleMoveUpDownThree 12s linear infinite;
    top: -220px;
    left: 29%;
  }

  .green-btn-svg-one {
    animation: GreenMoveUpDownOne 12s linear infinite;
    top: -211px;
    left: 29%;
  }

  .green-btn-svg-two {
    animation: GreenMoveUpDownTwo 12s linear infinite;
    top: -211px;
    left: 29%;
  }

  .green-btn-svg-three {
    animation: GreenMoveUpDownThree 12s linear infinite;
    top: -211px;
    left: 29%;
  }

  .brown-btn-svg-one {
    animation: BrownMoveUpDownOne 10s linear infinite;
    top: -234px;
    left: 29%;
  }

  .brown-btn-svg-two {
    animation: BrownMoveUpDownTwo 10s linear infinite;
    top: -234px;
    left: 29%;
  }

  .brown-btn-svg-three {
    animation: BrownMoveUpDownThree 10s linear infinite;
    top: -234px;
    left: 29%;
  }
  .btn {
    font-size: var(--size-sm);
  }

  .think-img-wrapper {
    margin-top: 6.5rem;
    margin-bottom: 13rem;
    padding-right: 4rem;
  }

  .think-img {
    width: 56%;
  }
  .request-access-container .request-access-group {
    margin-top: var(--size-xl);
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }

  .request-access-container .request-access-group .request-access-input {
    flex-basis: 50%;
  }

  .request-access-container .request-access-group {
    font-size: var(--size-sm);
    padding: 0.5rem 1rem;
  }

  .request-access-btn {
    font-size: var(--size-sm);
    padding: 0.5rem 1rem;
    width: 100%;
  }
}
/*sm*/
@media (min-width: 640px) {
  .set-wrapper svg {
    max-width: 60%;
    max-height: 60%;
  }

  .btn-svg svg {
    max-width: 60%;
    max-height: 60%;
  }

  .col-six-right {
    align-self: stretch;
    flex: 1;
    position: relative;
  }
  .absolute-one {
    top: -28px;
    left: -5%;
  }

  .absolute-two {
    top: -81px;
    right: -0%;
  }

  .absolute-three {
    top: 116px;
    left: -10%;
  }

  .absolute-four {
    top: 63px;
    right: 5%;
  }

  .blue-btn-svg-one {
    /* animation: BlueMoveUpDownOne 10s linear infinite; */
    width: 100x;
    top: -230px;
    left: 27%;
  }

  .blue-btn-svg-two {
    /* animation: BlueMoveUpDownTwo 10s linear infinite; */
    width: 100x;
    top: -230px;
    left: 27%;
  }

  .blue-btn-svg-three {
    /* animation: BlueMoveUpDownThree 10s linear infinite; */
    width: 100x;
    top: -230px;
    left: 27%;
  }

  .btn {
    font-size: var(--size-sm);
  }

  .think-img-wrapper {
    margin-top: 6.5rem;
    margin-bottom: 13rem;
    /* padding-right: 4rem; */
  }

  .think-img {
    width: 56%;
  }

  .navbar-container {
    background-color: #e9edf5;
  }
  .cloud_flex_direction h1{
    line-height: 4.5rem;
  }
}

/*md*/
@media (min-width: 768px) {
  .set-wrapper svg {
    max-width: 60%;
    max-height: 60%;
  }

  .col-six-right {
    align-self: stretch;
    flex: 1;
    position: relative;
  }

  .btn-svg svg {
    max-width: 50%;
    max-height: 50%;
  }

  .absolute-one {
    top: -28px;
    left: 2%;
  }

  .absolute-two {
    top: -81px;
    right: 10%;
  }

  .absolute-three {
    top: 116px;
    left: -2%;
  }

  .absolute-four {
    top: 71px;
    right: 15%;
  }
  .btn {
    font-size: var(--size-base);
  }

  .vision-animate-wrapper {
    top: 35%;
    left: -17%;
  }

  .vision-animate {
    width: 13rem;
  }

  .think-img-wrapper {
    margin-top: 6.5rem;
    margin-bottom: 13rem;
    /* padding-right: 5rem; */
  }

  .think-img {
    width: 56%;
  }

  .request-access-container .request-access-group {
    margin-top: var(--size-2xl);
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  .request-access-container .request-access-group .request-access-input {
    flex-basis: 60%;
  }

  .request-access-container .request-access-group {
    font-size: var(--size-sm);
    padding: 0.5rem 1rem;
  }

  .request-access-btn {
    font-size: var(--size-sm);
    padding: 0.5rem 1rem;
    width: 100%;
  }

  .intelligence-section {
    padding: 3rem 3rem;
  }

}

/*md-2*/
@media (min-width: 992px) {
  .cloud-outer {
    padding: 0rem 6rem !important;
  }
  .cloud_flex_direction {
    display: flex;
    flex-direction: row;
    text-align: left;
    overflow: hidden;
  }

  .updown_animation {
    margin-bottom: 0px;
  }

  .cloud_flex_direction {
    display: flex;
    flex-direction: row;
  }

  .cloud_flex_direction h1 {
    max-width: 16ch;
    margin-top: 4rem;
  }

  .cloud_flex_direction p {
    max-width: 42ch;
  }

  .updown_animation {
    margin-bottom: 0px;
  }

  .set-wrapper svg {
    max-width: 65%;
    max-height: 50%;
  }

  .col-six-right {
    align-self: stretch;
    flex: 1;
    position: relative;
  }

  .btn-svg svg {
    max-width: 60%;
    max-height: 60%;
  }

  .blue-btn-svg-one {
    animation: BlueMoveUpDownOne 10s linear infinite;
    top: -248px;
    left: 27%;
  }

  .blue-btn-svg-two {
    animation: BlueMoveUpDownTwo 10s linear infinite;
    top: -248px;
    left: 27%;
  }

  .blue-btn-svg-three {
    animation: BlueMoveUpDownThree 10s linear infinite;
    top: -248px;
    left: 27%;
  }

  .purple-btn-svg-one {
    animation: PurpleMoveUpDownOne 12s linear infinite;
    top: -248px;
    left: 27%;
  }

  .purple-btn-svg-two {
    animation: PurpleMoveUpDownTwo 12s linear infinite;
    top: -248px;
    left: 27%;
  }

  .purple-btn-svg-three {
    animation: PurpleMoveUpDownThree 12s linear infinite;
    top: -248px;
    left: 27%;
  }

  .green-btn-svg-one {
    animation: GreenMoveUpDownOne 12s linear infinite;
    top: -248px;
    left: 27%;
  }

  .green-btn-svg-two {
    animation: GreenMoveUpDownTwo 12s linear infinite;
    top: -248px;
    left: 27%;
  }

  .green-btn-svg-three {
    animation: GreenMoveUpDownThree 12s linear infinite;
    top: -248px;
    left: 27%;
  }

  .brown-btn-svg-one {
    animation: BrownMoveUpDownOne 10s linear infinite;
    top: -250px;
    left: 27%;
  }

  .brown-btn-svg-two {
    animation: BrownMoveUpDownTwo 10s linear infinite;
    top: -250px;
    left: 27%;
  }

  .brown-btn-svg-three {
    animation: BrownMoveUpDownThree 10s linear infinite;
    top: -250px;
    left: 27%;
  }

  .absolute-one {
    top: 10%;
    left: -10%;
  }

  .absolute-two {
    top: 6%;
    right: -10%;
  }

  .absolute-three {
    top: 37%;
    left: -15%;
  }

  .absolute-four {
    top: 26%;
    right: -5%;
  }

  .question-section {
    display: none !important;
  }

  .think-section {
    display: block;
  }

  .think-left-title {
    min-width: 450px;
  }

  .think-right-title {
    min-width: 450px;
  }

  .think-img-wrapper {
    padding-right: 3rem;
  }

  .big-hexa-svg svg {
    /* margin-top: 2rem; */
    /* width: 95%; */
  }

  .radial-svg {
    top: 26%;
    right: 0%;
  }

  .radial-svg svg {
    width: 70%;
  }

  .think-img-wrapper__FTnBg {
    margin-top: 6.5rem;
    margin-bottom: 13rem;
    /* padding-right: 5rem; */
  }

  .question {
    width: 30ch;
  }

  .join-btn {
    margin-bottom: 7rem !important;
  }

  /* .navbar-brand-wrapper {
    margin-left: 75px;
    margin-right: 0;
  } */
}

/*lg*/
@media (min-width: 1024px) {
  .cloud-outer {
    padding: 0rem 6rem !important;
  }
  .cloud_flex_direction {
    display: flex;
    flex-direction: row;
    overflow: unset;
  }

  .updown_animation {
    margin-bottom: 0px;
  }

  .cloud_flex_direction {
    display: flex;
    flex-direction: row;
  }

  .updown_animation {
    margin-bottom: 0px;
  }

  .set-wrapper svg {
    max-width: 100%;
    max-height: 100%;
  }

  .col-six-right {
    align-self: stretch;
    flex: 1;
    position: relative;
  }

  .btn-svg svg {
    max-width: 80%;
    max-height: 80%;
  }

  .blue-btn-svg-one {
    animation: BlueMoveUpDownOne 10s linear infinite;
    top: -232px;
    left: 17%;
  }

  .blue-btn-svg-two {
    animation: BlueMoveUpDownTwo 10s linear infinite;
    top: -232px;
    left: 17%;
  }

  .blue-btn-svg-three {
    animation: BlueMoveUpDownThree 10s linear infinite;
    top: -232px;
    left: 17%;
  }

  .purple-btn-svg-one {
    animation: PurpleMoveUpDownOne 12s linear infinite;
    top: -228px;
    left: 19%;
  }

  .purple-btn-svg-two {
    animation: PurpleMoveUpDownTwo 12s linear infinite;
    top: -228px;
    left: 19%;
  }

  .purple-btn-svg-three {
    animation: PurpleMoveUpDownThree 12s linear infinite;
    top: -228px;
    left: 19%;
  }

  .green-btn-svg-one {
    animation: GreenMoveUpDownOne 12s linear infinite;
    top: -218px;
    left: 19%;
  }

  .green-btn-svg-two {
    animation: GreenMoveUpDownTwo 12s linear infinite;
    top: -218px;
    left: 19%;
  }

  .green-btn-svg-three {
    animation: GreenMoveUpDownThree 12s linear infinite;
    top: -218px;
    left: 19%;
  }

  .brown-btn-svg-one {
    animation: BrownMoveUpDownOne 10s linear infinite;
    top: -247px;
    left: 19%;
  }

  .brown-btn-svg-two {
    animation: BrownMoveUpDownTwo 10s linear infinite;
    top: -247px;
    left: 19%;
  }

  .brown-btn-svg-three {
    animation: BrownMoveUpDownThree 10s linear infinite;
    top: -247px;
    left: 19%;
  }

  .absolute-one {
    top: -2%;
    left: 0%;
  }
  .absolute-two {
    top: -10%;
    right: 0%;
    left: 50%;
  }
  .absolute-three {
    top: 20%;
    left: -13%;
  }
  .absolute-four {
    top: 18%;
    right: 0%;
    left: 40%;
  }

  .vision-animate-wrapper {
    top: 30%;
    left: -17%;
  }

  .vision-animate {
    width: 17rem;
  }

  .think-img-wrapper {
    margin-top: 8rem;
    margin-bottom: 15rem;
    /* padding-right: 6rem; */
  }

  .think-img {
    width: 56%;
  }

  .request-access-container .request-access-group {
    margin-top: var(--size-4xl);
  }

  .request-access-container .request-access-group .request-access-input {
    flex-basis: 70%;
  }

  .request-access-group input::-webkit-input-placeholder {
    font-size: var(--size-xl);
  }

  .request-access-container .request-access-group {
    font-size: var(--size-base);
    padding: 1rem 1.5rem;
  }

  .request-access-btn {
    font-size: var(--size-base);
    padding: 1rem 1.5rem;
    width: 100%;
  }

  .intelligence-section {
    padding: 3rem 4rem;
  }

  .testimonials-section .testimonials-heading {
    display: block;
  }

  .testimonials-section .testimonials-heading-mob {
    display: none;
  }

  .question-section .question-heading {
    margin-top: 4rem;
  }

  .question-section .question-heading-desk {
    display: block;
  }

  .question-section .question-heading-mob {
    max-width: 16ch;
    display: none;
  }

  .question-section .question-section-right {
    display: block;
  }

  .question-section .question-section-left .mobile-hexagon {
    display: none !important;
  }

  .question-section {
    display: none !important;
  }

  .radial-svg svg {
    width: 80%;
  }

  .join-btn {
    margin-bottom: 10rem !important;
  }

  .polygon-box {
    width: 280px;
    height: 280px;
  }
}

/*xl*/
@media (min-width: 1280px) {
  .cloud-outer {
    padding: 0rem 6rem !important;
  }
  .set-wrapper svg {
    max-width: 100%;
    max-height: 100%;
  }

  .col-six-right {
    align-self: stretch;
    flex: 1;
    position: relative;
  }

  .btn-svg svg {
    max-width: 100%;
    max-height: 100%;
  }

  .blue-btn-svg-one {
    animation: BlueMoveUpDownOne 10s linear infinite;
    top: -248px;
    left: 27%;
  }

  .blue-btn-svg-two {
    animation: BlueMoveUpDownTwo 10s linear infinite;
    top: -248px;
    left: 27%;
  }

  .blue-btn-svg-three {
    animation: BlueMoveUpDownThree 10s linear infinite;
    top: -248px;
    left: 27%;
  }

  .purple-btn-svg-one {
    animation: PurpleMoveUpDownOne 12s linear infinite;
    top: -248px;
    left: 27%;
  }

  .purple-btn-svg-two {
    animation: PurpleMoveUpDownTwo 12s linear infinite;
    top: -248px;
    left: 27%;
  }

  .purple-btn-svg-three {
    animation: PurpleMoveUpDownThree 12s linear infinite;
    top: -248px;
    left: 27%;
  }

  .green-btn-svg-one {
    animation: GreenMoveUpDownOne 12s linear infinite;
    top: -248px;
    left: 27%;
  }

  .green-btn-svg-two {
    animation: GreenMoveUpDownTwo 12s linear infinite;
    top: -248px;
    left: 27%;
  }

  .green-btn-svg-three {
    animation: GreenMoveUpDownThree 12s linear infinite;
    top: -248px;
    left: 27%;
  }

  .brown-btn-svg-one {
    animation: BrownMoveUpDownOne 10s linear infinite;
    top: -288px;
    left: 27%;
  }

  .brown-btn-svg-two {
    animation: BrownMoveUpDownTwo 10s linear infinite;
    top: -288px;
    left: 27%;
  }

  .brown-btn-svg-three {
    animation: BrownMoveUpDownThree 10s linear infinite;
    top: -288px;
    left: 27%;
  }

  .absolute-one {
    top: 4%;
    left: 0%;
  }

  .absolute-two {
    top: -9%;
    right: 0%;
  }

  .absolute-three {
    top: 41%;
    left: -5%;
  }

  .absolute-four {
    top: 25%;
    right: 0%;
  }

  .vision-animate-wrapper {
    top: 25%;
    left: -23%;
  }

  .vision-animate {
    width: 24rem;
  }

  .think-img-wrapper {
    margin-top: 6.5rem;
    margin-bottom: 12rem;
    /* padding-right: 7rem; */
    /* gap: 5rem; */
  }

  .think-img {
    width: 58%;
  }

  .request-access-container .request-access-group {
    margin-top: var(--size-6xl);
  }

  .intelligence-section {
    padding: 3rem 6rem;
  }

  .question {
    width: 50ch;
  }

  .radial-svg {
    right: 5%;
    top: 28%;
  }

  .radial-svg svg {
    width: 90%;
  }

  /* @keyframes BlueMoveUpDownOne {
    0%,
    100% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-40px);
      opacity: 0;
    }
  }
  
  @keyframes BlueMoveUpDownTwo {
    0%,
    100% {
      opacity: 0;
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-40px);
      opacity: 1;
    }
  }
  
  @keyframes BlueMoveUpDownThree {
    0%,
    100% {
      opacity: 0;
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-40px);
      opacity: 1;
    }
  }

  @keyframes PurpleMoveUpDownOne {
    0%,
    100% {
      transform: translateY(-20px);
      opacity: 1;
    }
    50% {
      transform: translateY(-60px);
      opacity: 0;
    }
  }
  
  @keyframes PurpleMoveUpDownTwo {
    0%,
    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
    50% {
      opacity: 1;
      transform: translateY(-60px);
    }
  }
  
  @keyframes PurpleMoveUpDownThree {
    0%,
    100% {
      transform: translateY(-20px);
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(-60px);
    }
  }
  
  @keyframes GreenMoveUpDownOne {
    0%,
    100% {
      transform: translateY(-20px);
      opacity: 1;
    }
    50% {
      transform: translateY(-60px);
      opacity: 0;
    }
  }
  
  @keyframes GreenMoveUpDownTwo {
    0%,
    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
    50% {
      opacity: 1;
      transform: translateY(-60px);
    }
  }
  
  @keyframes GreenMoveUpDownThree {
    0%,
    100% {
      transform: translateY(-20px);
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(-60px);
    }
  }
  
  @keyframes BrownMoveUpDownOne {
    0%,
    100% {
      transform: translateY(0);
      opacity: 0;
    }
    50% {
      transform: translateY(40px);
      opacity: 1;
    }
  }
  
  @keyframes BrownMoveUpDownTwo {
    0%,
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(40px);
      opacity: 0;
    }
  }
  
  @keyframes BrownMoveUpDownThree {
    0%,
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(40px);
      opacity: 0;
    }
  } */
}

/*xl*/
@media (min-width: 1536px) {
  .cloud-outer {
    padding: 0rem 6rem !important;
  }
  .vision-animate-wrapper {
    top: 16%;
    left: -25%;
  }

  .vision-animate {
    width: 25rem;
  }

  .think-img-wrapper {
    margin-top: 8rem;
    margin-bottom: 15rem;
  }

  .think-img {
    width: 60%;
  }

  .question {
    width: 50ch;
  }
  .set-wrapper svg {
    max-width: 100%;
    max-height: 100%;
  }
  .btn-svg svg {
    max-width: 100%;
    max-height: 100%;
  }
  .blue-btn-svg-one {
    width: 80%;
  }
  .blue-btn-svg-two {
    width: 80%;
  }
  .blue-btn-svg-three {
    width: 80%;
  }
  .purple-btn-svg-one {
    width: 80%;
  }
  .purple-btn-svg-two {
    width: 80%;
  }
  .purple-btn-svg-three {
    width: 80%;
  }
  .green-btn-svg-one {
    width: 80%;
  }
  .green-btn-svg-two {
    width: 80%;
  }
  .green-btn-svg-three {
    width: 80%;
  }
  .brown-btn-svg-one {
    width: 80%;
  }
  .brown-btn-svg-two {
    width: 80%;
  }
  .brown-btn-svg-three {
    width: 80%;
  }
  .absolute-one {
    left: 10%;
  }
  .absolute-two {
    right: 0%;
  }
  .absolute-four {
    right: 0%;
  }

}
@media (min-width: 2000px) {
  .cloud-outer {
    padding: 0rem 6rem !important;
  }
  .set-wrapper svg {
    max-width: 100%;
    max-height: 100%;
  }
  .btn-svg svg {
    max-width: 100%;
    max-height: 100%;
  }
  .blue-btn-svg-one {
    width: 100%;
  }
  .blue-btn-svg-two {
    width: 100%;
  }
  .blue-btn-svg-three {
    width: 100%;
  }
  .purple-btn-svg-one {
    width: 100%;
  }
  .purple-btn-svg-two {
    width: 100%;
  }
  .purple-btn-svg-three {
    width: 100%;
  }
  .green-btn-svg-one {
    width: 100%;
  }
  .green-btn-svg-two {
    width: 100%;
  }
  .green-btn-svg-three {
    width: 100%;
  }
  .brown-btn-svg-one {
    width: 100%;
  }
  .brown-btn-svg-two {
    width: 100%;
  }
  .brown-btn-svg-three {
    width: 100%;
  }
  .absolute-one {
    left: 20%;
  }
  .absolute-two {
    right: 0%;
  }
  .absolute-four {
    right: 20%;
  }
}
/*utils classes end*/
.corner {
  position: absolute;
}
#reaction .corner {
  background: none;
  height: 22px;
  width: 69px;
}
#top-left {
  top: 0;
  left: 0;
  border-radius: 17px 0 0 0;
  border-top: 2px solid black;
  border-left: 2px solid black;
}
#top-right {
  top: 0;
  right: 0;
  border-radius: 0 5px 0 0;
  border-top: 2px solid black;
  border-right: 2px solid black;
}
#bottom-right {
  bottom: 0;
  right: 0;
  border-radius: 0 0 5px 0;
  border-bottom: 2px solid black;
  border-right: 2px solid  black;
}
#bottom-left {
  bottom: 0;
  left: 0;
  border-radius: 0 0 0 17px;
  border-bottom: 2px solid black;
  border-left: 2px solid black;
}
.content {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.icon {
  height: 24;
  width: 24;
}
.title {
  font-size: 20px;
}
#reaction .title {
  color: black;
}
.score {
  color: '#444444'
}
.item1 {
  max-width: 40%;
  padding-left: 40px;
  line-height: 1.1rem;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 5px;
  height: 22%;
  top: 9.9%;
  left: 60%;
}
.item2 {
  max-width: 80%;
  padding-left: 40px;
  line-height: 1.1rem;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 5px;
  height: 22%;
  top: 32%;
  left: 40%;
}
.item3  {
  max-width: 70%;
  padding-left: 40px;
  line-height: 1.1rem;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 5px;
  height: 22%;
  top: 54%;
  left: 20%;
}
.puzzlesImg {
  position: absolute;
  left: -23px;
  top: 44px;
}
.dollarImg {
  position: absolute;
  left: -28px;
  top: 40px;
}

/* .puzzleDivContainer {
  font-family: 'Work Sans', sans-serif;
  text-align: left;
  align-items: flex-start;

}
.puzzleTextContainer {
  max-width: 40%;
} */
/* .puzzleTextFlex {
  display: flex;
  flex-direction: column;
  padding-top: 5%;
  text-align: left;
} */
.f-38 {
  font-size: 38px;
}
.f-72 {
  font-size: 72px;
  line-height: 80px;

}
.puzzlesContainer{
  margin-left: 50%;
  position: relative;
}

.first-tile{
  position: absolute;
  width: 100%;
  top: 13%;
}
.second-tile{
  position: absolute;
   width: 100%;
   bottom: 15%;
}
.third-tile{
  position: absolute;
   width: 100%;
   left: -30%;
}
.fourth-tile{
  position: absolute;
   width: 100%;
   right: 25%;
   bottom: 25%;
}
.blockTick{
  position: absolute;
  top: 0;
  left: 70%;
}

@media only screen and (max-width: 995px) {
  .cloud_flex_direction {
    display: flex;
    /* flex-direction: column-reverse; */
  }
  .col-lg-6 {
    width: 100%;
  }
  .first-tile {
      top: -20px;
      left: 25%;
  }
  .second-tile{
     bottom: 0px;
     top: -68px;
  }
  .third-tile{
     top: 141px;
     left: 10px;
  }
  .fourth-tile{
     right: 0px;
     bottom: 0px;
     top: 84px;
     left: -20%;
  }
}
.puzzelsImage {
  width: 920px;
  height: 100%;
}
@media only screen and (min-width: 995px) {
  .first-tile {
      top: 49px;
      left: 25%;
  }
  .second-tile{
     bottom: 0px;
     top: -27px;
  }
  .third-tile{
     top: 0px;
     left: 10px;
  }
  .fourth-tile{
     right: 0px;
     bottom: 0px;
     top: -80px;
     left: -20%;
  }
  .set-wrapper svg {
    max-width: 100%;
    max-height: 100%;
  }
}
.fold3ImgContainer{
  position: relative;
  width: 50%;
  right: 4%;
}
.leftSidePuzzelSec {
  position: relative;
  width: 50%;
  right: 3%;
}

@media only screen and (max-width: 1440px) {
  .puzzelsImage {
    width: 551px;
  }
 .blockTick{
  top: -42px;
  left: 33%;
 }
 .puzzlesImg {
  top: 21px;
}
}
@media only screen and (max-width: 1024px) {
  .puzzelsImage {
    width: 548px;
  }
  .title {
    font-size: 16px;
  }
  .puzzlesImg{
    top: 19px;
  }
  .item2 {
    left: 6%;
  }
  .blockTick {
    top: -40px;
    left: 46%;
  }
  .f-38{
    font-size: 20px;
  }
  .f-72 {
    font-size: 44px;
    line-height: 58px;
  }
}

@media only screen and (max-width: 768px) {
  /* .fold3ImgContainer {
    display: none;
  } */
  .leftSidePuzzelSec {
    height: 500px;
  }
  .f-38{
    font-size: 15px;
  }
  .f-72 {
    font-size: 44px;
    line-height: 58px;
  }
  /* .puzzleTextContainer {
    margin-right: 0%;
    max-width: 87%;
  } */
  .leftSideTextPart {
    width: 100%;
  }
  .title {
    font-size: 16px;
  }
  .item1 {
    left: 10%;
    max-width: 65%;
  }
  .item2 {
    top: 38%;
    left: 10%;
    max-width: 65%;
  }
  .item3 {
    top: 67%;
    left: 10%;
    max-width: 65%;
  }
  .leftSidePuzzelSec {
    width: 100%;
    right: -6%;
  }
  /* .puzzleDivContainer{
    align-items: center;
  } */
  .puzzlesImg {
    top: 31px;
  }
}
@media only screen and (max-width: 425px) {
  /* .fold3ImgContainer {
    display: none;
  } */
  .leftSidePuzzelSec {
    height: 500px;
  }
  .f-38{
    font-size: 15px;
  }
  .f-72 {
    font-size: 44px;
    line-height: 58px;
  }
  /* .puzzleTextContainer {
    margin-right: 0%;
    max-width: 87%;
  } */
  .leftSideTextPart {
    width: 100%;
  }
  .title {
    font-size: 16px;
  }
  .item1 {
    left: 4%;
    max-width: 47%;
  }
  .item2 {
    top: 38%;
    left: 10%;
    max-width: 72%;
  }
  .item3 {
    top: 67%;
    left: 4%;
    max-width: 64%;
  }
  .leftSidePuzzelSec {
    width: 100%;
    right: -6%;
  }
  /* .puzzleDivContainer{
    align-items: flex-end;
  } */
  .puzzlesImg {
    top: 31px;
  }
}

.puzzleDivContainer {
  font-family: 'Work Sans', sans-serif;
  text-align: left;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 5rem;
  background-color: #FFFFFF;
}

.puzzleTextContainer {
  max-width: 40%;
}

.puzzleTextFlex {
  display: flex;
  flex-direction: column;
  padding-top: 5%;
  text-align: left;
}

.textParagraph {
  max-width: 40ch;
  font-size: 24px;
  font-weight: 400;
}

.imageContainer {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  margin-bottom: 4rem;
}

.utilsImage {
  width: 100%;
  height: 100%;
  margin-top: 20%;
}

@media (max-width: 1280px) {
  .puzzleDivContainer {
    margin-left: 3rem;
  }

  .puzzleTextContainer {
    max-width: 50%;
  }

  .puzzleTextFlex {
    padding-top: 2%;
  }

  .textParagraph {
    font-size: 20px;
    max-width: 90%;
  }

  .utilsImage {
    margin-left: 0;
    width: 80%;
  }
}

@media (max-width: 768px) {
  .puzzleDivContainer {
    margin-left: 1rem;
    flex-direction: column;
  }

  .imageContainer {
    order: -1;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }

  .utilsImage {
    width: 100%;
    height: auto;
    margin-left: 20px;
  }

  .f-38 {
    font-size: 30px;
    text-align: center;
  }

  .textParagraph {
    font-size: 18px;
    text-align: center;
  }

  .puzzleTextContainer {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .f-38 {
    font-size: 26px;
    text-align: center;
  }

  .textParagraph {
    text-align: center;
    font-size: 18px;
    padding: 0px 20px;
  }

  .utilsImage {
    width: 100%;
    height: auto;
    margin-left: 10px;
  }
}

@font-face {
  font-family: 'Sue Ellen Francisco', sans-serif;
  src: url('../../../fonts/Sue_Ellen_Francisco/SueEllenFrancisco-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


.carousel.vertical .carousel-inner {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 550px;
    overflow: hidden;
}

.carousel-item {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    transition: transform 0.5s ease-in-out, visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 0;
}

.carousel-item.active {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
    z-index: 1;
}

.carousel-item-next {
    transform: translateY(100%);
    visibility: hidden;
    opacity: 1;
    z-index: 1;
}

.carousel-item-prev {
    transform: translateY(-100%);
    visibility: hidden;
    opacity: 0;
    z-index: 0;
}

.carousel-control-prev,
.carousel-control-next {
    display: none;
}

.product-carousel-container {
    padding-top: 80px;
}

.product-carousel-item {
    display: flex;
    justify-content: space-between;
    margin: 0 6rem;
    align-items: center;
}

.product-content {
    display: flex;
    justify-content: center;
    margin-left: 110px;
    gap: 9rem;
    padding-right: 20px;
}

.product-text-content {
    width: 45%;
    text-align: left;
}

.product-img-title {
    font-size: 48px;
    font-family: 'Work Sons', sans-serif;
    max-width: 17ch !important;
    font-weight: 700;
}

.product-detail {
    font-size: 24px;
    font-family: 'Work Sons', sans-serif;
    max-width: 32ch;
}

.product-image-content {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-img {
    max-width: 100%;
    height: auto;
    max-height: 500px;
    border-radius: 32px;
}

.product-footer-image {
    height: 140px;
}

@media (max-width: 768px) {
    .carousel.vertical .carousel-inner {
        height: 180px;
    }

    .product-carousel-container {
        padding-top: 30px;
    }

    .product-carousel-item {
        margin: 0 1.5rem;
        flex-direction: row;
        align-items: center;
        gap: 0.8rem;
    }

    .product-content {
        flex-direction: column;
        margin-left: 0;
        gap: 1rem;
        padding-right: 0;
    }

    .product-text-content {
        width: 200px;
        text-align: left;
    }

    .product-img-title {
        font-size: 16px;
        font-weight: 700;
    }

    .product-detail {
        font-size: 12px;
    }

    .product-image-content {
        width: 100%;
    }

    .product-img {
        border-radius: 12px;
        max-width: 100%;
        max-height: 250px;
    }

    .product-footer-image {
        height: 80px;
    }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Work Sans', sans-serif;
  color: #333;
  text-align: left;
}

.product-title {
  display: block;
  font-size: 2.0rem;
  font-weight: bold;
}

.introduction strong {
  font-size: 1.2rem;
}

.product-image {
  text-align: left;
  margin: 20px 0;
}

.product-image img {
  border-radius: 8px;
  width: 90%;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.sub-title {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 10px;
}

.use-cases ul,
.benefits ul {
  list-style-type: disc;
  margin-left: 10px;
}

.use-cases ul li,
.benefits ul li {
  margin-bottom: 3px;
  font-size: 1rem;
}

.responsive-image-small {
  width: 100%; /* Ensures full width on smaller screens */
  max-width: 30vw; /* Restricts width to 30% of the viewport on larger screens */
  height: auto; /* Maintains aspect ratio */
  display: block;
  margin: 0 auto; /* Centers the image */
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.responsive-image-medium {
  width: 75%; /* Ensures full width on smaller screens */
  max-width: 75vw; /* Restricts width to 90% of the viewport on larger screens */
  height: auto; /* Maintains aspect ratio */
  display: block;
  margin: 0 auto; /* Centers the image */
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.responsive-image-large {
  width: 95%; /* Ensures full width on smaller screens */
  max-width: 95vw; /* Restricts width to 90% of the viewport on larger screens */
  height: auto; /* Maintains aspect ratio */
  display: block;
  margin: 0 auto; /* Centers the image */
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .responsive-image-small {
    width: 75%;
    max-width: 100%; /* Overrides to full width for mobile */
  }
  .responsive-image-medium {
    width: 90%;
    max-width: 100%; /* Overrides to full width for mobile */
  }
  .responsive-image-large {
    width: 100%;
    max-width: 100%; /* Overrides to full width for mobile */
  }

  .left-column {
    order: 1;
  }
  .right-sidebar {
    order: 2; /* Ensure it appears after the main content */
  }

}


.button_container {
  border-bottom: 1px solid #4A4A4A;
}
.nav-item .active{
    border-bottom:none !important
}
.heading {
  opacity: 30%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-left: 2rem;
  margin-bottom:1.5rem;
}
.heading_synced {
  opacity: 0.5;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.model_container {
  min-height: 545px;
}

.connect_button {
  border-radius: 4px;
  border: 1px;
  padding: 16px 32px;
  background-color: rgba(63, 81, 181, 1);
  opacity: 50%;
  font-size: 18px;
  font-weight: 400;
  width: 45%;
  line-height: 21.6px;
  font-family: 'Work Sans', sans-serif;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.custom_button_container {
  margin-left: -2.7rem;
  margin-right: -2.7rem;
}

/* .synced_list{
  border-bottom:1px solid rgba(74, 74, 74, 1);
  margin-bottom:1rem;
}


.synced_list img {
  width: 15px;
  height: 10px;
  cursor: pointer;
}

.synced_list span {
  flex-grow: 1;
  font-size: 12px;
  color: white;
  margin: 5px;
} */

/* styles.module.css */
.synced_list {
  border-bottom: 1px solid rgba(74, 74, 74, 1);
  margin-bottom: 1rem;
  align-items: center;
  display: flex;
}

.cloud_platform_image {
  width: 20px;
  height: 10px;
}

.text {
  margin-right: 5px;
  margin-left: 10px;
  font-size: x-small;
  word-break: break-all;
}

.reset_image {
  width: 24px;
  height: 24px;
  margin-left: 1px;
  margin-left: auto;
}

.disconnect_image {
  width: 24px;
  height: 24px;
  margin-left: 1px;
}

.status_image {
  height: 24px;
  margin-left: 1px;
}


.synced_container ul{
  list-style-type: none;
  padding-left:0rem;
}
.synced_container li{
  color: rgba(179, 170, 255, 1);
  padding: 6px 2px;
}
.nav_tabs button:hover {
  border-color: none !important;
  color: #4A4A4A;
}

.nav_tabs {
  border-bottom: 1px  #4A4A4A !important;
  color: #4A4A4A;
}

.nav__links{
  transition:none !important;
}

.cloud_inputbox{
  border: 1px solid #747474;
  border-radius: 2px;
}
.cloud_buttons{
  min-height:80px;
  border: 1px solid #4F4F4F;
  border-radius: 4px;
}

.closeImgSvg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  cursor: pointer;
}
.server-main-container {
  display: flex;
  flex-direction: column;
  gap:1rem;
}
.server-container {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  min-height: 270px;
}
.arnRole-input {
  width: 100%;
  background-color: #444444;
  padding: 10px 30px 10px 10px;
  color: white;
}
.instructionDiv {
  background: rgba(56, 56, 56, 1);
  border-radius: 8px;
}
.f-12 {
  font-size: 12px;
}
.giveAccessBtn{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}
.input_Position {
  position: relative;
}
.giveAccessDiv {
  display: flex;
  justify-content: center;
}
.arnRole{
  margin-left: 10px;
   margin-right: 8px;

}
.width-4{
  width: 4%;
}
.width-7{
  width: 7%;
}
.width-8{
  width: 8%;
}
.give-access-highlight {
  background: #5a46ff;
  color: white;
  opacity: 100%;
}.nav-item .active {
  border-bottom: none !important;
}

.heading_synced {
  opacity: 0.5;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.model_container {
  min-height: 545px;
}

.button_container {
  border-bottom: 1px solid #4A4A4A;
}

.custom_button_container {
  margin-left: -2.7rem;
  margin-right: -2.7rem;
}

.heading {
  opacity: 0.3;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-left: 2rem;
  margin-bottom: 1.5rem;
}

.synced_list {
  border-bottom: 1px solid rgba(74, 74, 74, 1);
  margin-bottom: 1rem;
}

.synced_container ul {
  list-style-type: none;
  padding-left: 0rem;
}

.synced_container li {
  color: rgba(179, 170, 255, 1);
  padding: 6px 2px;
}

.nav_tabs button:hover {
  border-color: none !important;
  color: #4A4A4A;
}

.nav_tabs {
  border-bottom: 1px #4A4A4A !important;
  color: #4A4A4A;
}

.nav__links {
  transition: none !important;
}


.cloud_buttons {
  min-height: 80px;
  border: 1px solid #4F4F4F;
  border-radius: 4px;
}

.closeImgSvg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  cursor: pointer;
}

.server_main_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.server_container {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  min-height: 270px;
}

.externalID_container {
  position: relative;
}

.showPastExternalIds_button {
  border-radius: 2px;
  border: 1px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgba(63, 81, 181, 1);
  width: 100%;
  line-height: 14px;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  display: inline-flex;
  justify-content: center;
}

.awsAccountId_inputbox {
  border: 1px solid #747474;
  border-radius: 2px;
  width: 45%;
  background-color: #444444;
  padding: 10px 30px 10px 10px;
  color: white;
  margin-right: 5%;
  font-size: 14px;
}

.getExternalId_button {
  border-radius: 2px;
  border: 1px;
  padding: 10px 30px;
  background-color: rgba(63, 81, 181, 1);
  font-size: 18px;
  font-weight: 400;
  width: 45%;
  font-family: 'Work Sans', sans-serif;
  display: inline-flex;
  justify-content: center;
  margin-left: 5%;
  font-size: 16px;
}

.externalIdTable_container {
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
}
.externalIdTable_container::-webkit-scrollbar{
  width: 6px;
  height: 6px;
}

.externalIdTable_container::-webkit-scrollbar-track{
  background-color: transparent;
  border-radius: 10px;
  width: 100px;
}

.externalIdTable_container::-webkit-scrollbar-thumb{
  background-color: #818B99;
  border-radius: 10px;
}

.externalIdTable_header {
  padding: 8px;
  border: 1px solid #444444;
  text-align: left;
}

.externalIdTable td,
.externalIdTable th {
  border: 1px solid #444444;
  padding: 8px;
  font-size: 12px;
}

.arnRole_container {
  position: relative;
  padding-top: 10%;
}

.arnRole_inputbox {
  border: 1px solid #747474;
  border-radius: 10px;
  width: 100%;
  background-color: #444444;
  padding: 10px 30px 10px 10px;
  color: white;
}

.instructions_container {
  background: rgba(56, 56, 56, 1);
  border-radius:10px;
  margin-top: 12px;
}

.giveAccess_container {
  display: flex;
  justify-content: center;
}

.giveAccess_button {
  border-radius: 5px;
  border: 1px;
  padding: 16px 32px;
  background-color: rgba(63, 81, 181, 1);
  opacity: 0.5;
  font-size: 18px;
  font-weight: 400;
  width: 45%;
  line-height: 21.6px;
  font-family: 'Work Sans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.giveAccess_button_highlight {
  background: #5a46ff;
  color: white;
  opacity: 1;
}

.f-12 {
  font-size: 12px;
}

.width-4 {
  width: 4%;
}

.width-7 {
  width: 7%;
}

.width-8 {
  width: 8%;
}

.error_messages {
  color: white;
  font-size: 15px;
}

.disable-img {
  opacity: 0.4;
  pointer-events: none;
}

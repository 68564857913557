.header-border {
    border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.header-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
}

.header-body img {
    max-width: 150px;
    height: auto;
}

.join-btn {
    background: rgba(90, 70, 255, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif;
    width: 132px;
    height: 51px;
    border-radius: 4px;
    padding: 15px;
    cursor: pointer;
}

.title-description{
    padding-top: 15px;
}

.blog-title-description{
    padding-top: 5px;
}

@media (min-width: 768px) {
    .header-body {
        padding-right: 100px;
        padding-left: 100px;
    }
}

@media (min-width: 1024px) {
    .header-body {
        padding-right: 150px;
        padding-left: 150px;
    }
}

.news-text {
    font-weight: 700;
    font-size: 24px;
    padding-top: 30px;
    margin-bottom: 20px;
    text-align: center;
    color: #333333;
}

.title-img {
    display: flex;
    padding: 15px 10px;
    gap: 10px;
}

.title-img img {
    width: 40px;
    height: 40px;
}

.title-img span {
    flex: 1;
    font-size: 16px;
    color: #333333;
}

.press-blog-index-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    padding-right: 140px;
    padding-left: 140px;
}

.left-column {
    flex: 2;
    padding: 20px 8px;
    text-align: left;
}

.news-title {
    font-weight: 800;
    font-size: 36px;
    padding-left: 0;
    font-family: 'Work Sans', sans-serif;
}

.blog-title {
    font-weight: 800;
    font-size: 28px;
    padding-left: 0;
    font-family: 'Work Sans', sans-serif;
}

.resources-container {
    padding-top: 20px;
    margin-top: 20px;
}

.press-blog-image-container img {
    width: 100%;
    height: auto;
    max-width: 885px;
    margin: 0 auto;
    cursor: pointer;
}

.equal-words {
    word-wrap: break-word;
    width: 100%;
    display: block;
    max-width: 885px;
}

.winner-texts, .resource-title {
    font-size: 32px;
    font-weight: 700;
    margin-top: 10px;
    font-family: 'Work Sans', sans-serif;
    word-wrap: break-word;
    width: 100%;
    display: block;
    max-width: 885px;
}

.resource-title-small {
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
    font-family: 'Work Sans', sans-serif;
    word-wrap: break-word;
    width: 100%;
    display: block;
    max-width: 885px;
}

.winner-description, .resource-description {
    color: #333;
    padding-top: 10px;
    line-height: 1.6;
    font-family: 'Work Sans', sans-serif;
}

.right-sidebar {
    flex: 1;
    min-width: 280px;
    max-width: 300px;
    font-family: 'Work Sans', sans-serif;
    color: #333333;
    font-weight: 600;
}

footer {
    background-color: #f1f1f1;
    padding: 20px;
    text-align: center;
    flex-shrink: 0;
}

/* .resource-title:hover {
    color: rgba(90, 70, 255, 1);
    cursor: pointer;
} */

.sidebar-section {
    margin-bottom: 30px;
}

.sidebar-section h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
}

.categories {
    list-style-type: none;
    padding: 0;
    font-family: 'Work Sans', sans-serif;
}

.categories li {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin: 5px 0;
    border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.recent-posts {
    list-style-type: none;
    padding: 0;
}

.recent-posts li {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.recent-posts img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin-right: 10px;
}

.recent-posts span {
    font-size: 15px;
    color: #333333;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.tags span {
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(238, 238, 238, 1);
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 14px;
    color: #333333;
    cursor: pointer;
}

@media (max-width: 768px) {
    .header-body {
        padding-right: 20px;
        padding-left: 20px;
    }

    .news-text {
        font-size: 20px;
        padding-top: 20px;
    }

    .title-img {
        padding: 10px 5px;
    }

    .title-img img {
        width: 35px;
        height: 35px;
    }

    .title-img span {
        font-size: 14px;
    }

    .press-blog-index-container {
        flex-direction: column;
        padding: 20px;
        padding-left: 80px;
        padding-right: 80px;
    }

    .left-column, .right-sidebar {
        max-width: 100%;
        padding: 10px;
    }

    .news-title {
        font-size: 28px;
    }

    .blog-title {
        font-size: 24px;
    }

    .winner-texts, .resource-title {
        font-size: 24px;
    }

    .right-sidebar {
        order: -1;
        max-width: 100%;
        padding: 15px;
    }

    .sidebar-section h3 {
        font-size: 16px;
    }

    .categories li {
        font-size: 14px;
    }

    .recent-posts span {
        font-size: 14px;
    }

    .tags span {
        font-size: 12px;
        padding: 4px 8px;
    }
}

@media (max-width: 480px) {
    .news-text {
        font-size: 18px;
        padding-top: 15px;
    }

    .title-img {
        flex-direction: column;
        align-items: flex-start;
    }

    .title-img img {
        width: 30px;
        height: 30px;
    }

    .title-img span {
        font-size: 12px;
        text-align: left;
    }

    .press-blog-index-container {
        padding: 10px;
    }

    .news-title, .winner-texts, .resource-title {
        font-size: 20px;
    }

    .blog-title {
        font-size: 20px;
    }

    .winner-description, .resource-description {
        font-size: 14px;
        line-height: 1.4;
        font-family: 'Work Sans', sans-serif;
    }

    .recent-posts li {
        flex-direction: column;
        align-items: flex-start;
    }

    .recent-posts img {
        margin-bottom: 5px;
    }

    .right-sidebar {
        padding: 10px;
    }
}

/* **** Detail Page *****/

.back-link {
    display: flex;
    align-items: center;
    position: absolute;
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
    font-size: 1rem;
    top: 10%;
    left: 8.5%;
}

.back-icon {
    height: 13px;
    padding-right: 5px;
}

.image-section {
    position: relative;
}

.image-section img {
    width: 100%;
    display: block;
}

.main-title {
    font-weight: 700;
    font-size: 40px;
}

.main-description{
    font-size: 24px;
}

.image-text-overlay {
    position: absolute;
    color: rgba(255, 255, 255, 1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    width: 90%;
    max-width: 100%;
    box-sizing: border-box;
    font-family: 'Work Sans', sans-serif;
}

@media (max-width: 768px) {
    .image-text-overlay {
        top: 60%;
    }
    .image-text-overlay h2 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }
    .image-text-overlay p {
        font-size: 1rem;
        line-height: 1.3;
    }

    .back-link {
        top: 5%;
        left: 7%;
        font-size: 0.9rem;
    }

    .back-icon {
        height: 11px;
        padding-right: 2px;
    }

    .main-title {
        font-size: 1.8rem;
    }

    .main-description{
        font-size: 22px;
    }
}

@media (max-width: 480px) {
    .image-text-overlay {
        padding: 0.8rem;
        top: 65%;
    }
    .image-text-overlay h2 {
        font-size: 1.2rem;
        margin-bottom: 0.4rem;
    }
    .image-text-overlay p {
        font-size: 0.9rem;
        line-height: 1.2;
    }

    .back-link {
        top: 3%;
        left: 4%;
        font-size: 0.8rem;
        gap: 3px;
    }

    .back-icon {
        height: 10px;
        padding-right: 2px;
    }

    .main-title {
        font-size: 1.5rem;
    }

    .main-description{
        font-size: 18px;
    }
}

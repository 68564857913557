.auto_fill_button {
  font-size: 14px !important;
  padding: 5px 10px !important;
  background-color: #cf4729 !important; /* Vibrant background color */
  color: #fff !important; /* White text color */
  border: 2px solid #ff5733 !important; /* Border matching the background color */
  border-radius: 5px !important; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important; /* Subtle shadow */
  transition: background-color 0.3s ease, transform 0.3s ease !important; /* Smooth transitions */
  margin-bottom: 0.5rem !important;
}

.auto_fill_button:hover {
  background-color: #ff784e !important; /* Slightly lighter on hover */
}
.main-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* padding: 1rem; */
  align-items: flex-end;
  padding-bottom: 5rem;
}

.pills-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #404040;
  border-radius: 8px;
  overflow: hidden;
  width: fit-content;
  background-color: #000;
}

.custom-btn {
  background: transparent;
  padding: 12px !important;
  min-width: 48px;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  border-radius: 8px;
  border: 1px solid #ababab;
  background: #0c0c0c;
  backdrop-filter: blur(7.5px);
  padding: 6px;
}

.eye-container .custom-btn {
  border-radius: 0px !important;
}

.eye-container > div:first-child .custom-btn {
  border-radius: 0px !important;
  border-bottom: 1px solid #404040;
}

.custom-btn.active {
  background: #5a46ff;
  backdrop-filter: blur(7.5px);
}

.range-percentage-container {
  border-radius: 0px !important;
  background: #000;
  width: 48px;
}

.range-percentage {
  color: #fff;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.11px;
}

.undo-redo-container {
  flex-direction: row;
  gap: 0.5rem;
  background-color: #000;
}

.undo-redo-container .custom-btn {
  border: 1px solid #404040;
}

.plus-minus-container > div:first-child .custom-btn {
  border-bottom: 1px solid #404040 !important;
}

.plus-minus-container > div:last-child .custom-btn {
  border-top: 1px solid #404040 !important;
}
.plus-minus-container {
  width: 50px;
}
.range-btn {
  transition: transform 0.2s;
}

.range-btn:hover svg {
  transform: scale(1.4);
}
.redo-range-btn {
  transition: transform 0.2s;
}
.redo-range-btn:hover svg{
  transform: scale(-1.4,1.4);
}
.zoom_input {
  background: transparent;
  color: #fff;
  width: fit-content;
  max-width: 23px;
  border: none;
  text-align: center;
  font-size: 12px;
}

.zoom_input:focus {
  border: none;
  outline: none;
}

.custom-btn span {
  font-size: 12px;
}

input[type="color"] {
	/* -webkit-appearance: none; */
	border: none;
	width: 18px;
	height: 16px;
  background-color: black;
  cursor: pointer;
}
input[type="color"]:hover {
  transform: scale(1.4);
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border-radius: 25px;
  /* width: 5px; */
  /* height: 5px; */
}

.zoom_input::-webkit-inner-spin-button,
.zoom_input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.fitViewBtn {
  cursor: pointer;
  width: 18px;
  height: 18px;
}
.fitViewBtn:hover{
  transform: scale(1.4);
}
.toolbar {
  padding: 5px;
}

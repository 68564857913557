.feedback-btn {
    color: white;
    width: 120px;
    border-radius: 6px;
    font-size: 16px;
    height: 37px;
    margin-top: 7px;
    font-family: 'Work Sans', sans-serif;
    border: 1px solid #3d3d3d;
    position: absolute;
    right: 70px;
    bottom: 28px;
    background-color: #0c0c0c;
  }
  .feedback-btn:hover {
    border: none;
    background-color: #5a46ff;
  }

  .feedback-btn-img{
    filter: invert(1);
    opacity: 0.9;
    transform: scale(0.8);
    padding-right: 3px;
  }

  /* Container styles */
  .feedback-container {
    font-family: 'Work Sans', sans-serif;
  }

  .feedback-container p {
    padding-bottom: 20px;
  }
  
  .feedback-group {
    margin-bottom: 10px;
  }
  
  .custom-input {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 0px !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: 1px solid #e4e4e4 !important;
  }

  .message-input {
    margin-bottom: 10px;
    padding-left: 15px;
    border-radius: 0px !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: 1px solid #e4e4e4 !important;
  }

  .custom-input::placeholder,
  .message-input::placeholder {
    color: #6e6e6e;
    opacity: 0.6;
  }

  .submit-feedback-btn {
    background-color: #5a46ff;
    color: white;
    border-radius: 8px;
    font-size: 16px;
    height: 37px;
    margin-top: 7px;
    font-family: 'Work Sans', sans-serif;
    width: 100%;
  }
  
  .submit-feedback-btn:hover {
    border: none;
    background-color: #5a46ff;
  }
  
  .error {
    border-color: red;
  }
  
  .error-text {
    color: red;
    font-size: 0.9rem;
  }
  
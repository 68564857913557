.heading {
  opacity: 0.5;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.bg-color > div {
  background: #2f2f2f !important;
}

.all-btn-wrapper {
  border-radius: 4px;
  width: 240px;
  min-height: 48px;
  border: 1px solid #4f4f4f;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  transition: all 0.3s ease;
  overflow: hidden;
}

.all-btn-wrapper .group-btn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  border-radius: 4px;
}

.all-btn-wrapper .group-btn:hover {
  background-color: #464646;
}

.all-btn-wrapper .group-btn.active {
  background: #fff;
}

.custom-input {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  background: #444 !important;
}

.custom-input::placeholder {
  color: #fff !important;
}

.cust-btn {
    background-color: #3F51B5;
    border: 1px solid #3F51B5;
    display: flex;
    text-align: center;
    color: #fff;
    padding: 16px 32px;
    border-radius: 4px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    gap: 1rem;
}
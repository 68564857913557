.btn-add-synced {
  border: 1px solid rgba(74, 74, 74, 1);
  padding: 6px;
  width: 82px;
  border-bottom: 1px solid #1e1e1e;
  border-radius: 4px;
  z-index: 999;
}
.button_container {
  border-bottom: 1px solid #4A4A4A;
}
.nav-item .active{
    border-bottom:none !important
}
.heading {
  opacity: 30%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-left: 2rem;
  margin-bottom:1.5rem;
}
.heading_synced {
  opacity: 0.5;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.model_container {
  min-height: 545px;
}
.model-title {
  font-family: "Work Sans", sans-serif !important;
}
.connect_button {
  border-radius: 4px;
  border: 1px;
  padding: 16px 32px;
  background-color: rgba(63, 81, 181, 1);
  opacity: 50%;
  font-size: 18px;
  font-weight: 400;
  width: 45%;
  line-height: 21.6px;
  font-family: 'Work Sans', sans-serif;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.enable_connect_btn {
  border-radius: 4px;
  border: 1px;
  padding: 16px 32px;
  background: #5a46ff;
  color: white;
  font-size: 18px;
  font-weight: 400;
  width: 45%;
  line-height: 21.6px;
  font-family: 'Work Sans', sans-serif;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.custom_button_container {
  margin-left: -2.7rem;
  margin-right: -2.7rem;
}
.synced_list{
  border-bottom:1px solid rgba(74, 74, 74, 1);
  margin-bottom:1rem;
}
.synced_container ul{
  list-style-type: none;
  padding-left:0rem;
}
.synced_container li{
  color: rgba(179, 170, 255, 1);
  padding: 6px 2px;
}
.nav_tabs button:hover {
  border-color: none !important;
  color: #4A4A4A;
}
.nav_tabs {
  border-bottom: 1px  #4A4A4A !important;
  color: #4A4A4A;
}
.nav__links{
  transition:none !important;
}
.url_inputbox {
  border: 1px solid #747474;
  border-radius: 4px;
  width: 100%;
  background: rgb(18 18 18);
  padding: 10px 0px 10px 10px;
  color: white;
}
.url_inputbox:focus {
  border: 3px solid #747474;
}
.inputbox {
  border: 1px solid #747474;
  border-radius: 4px;
  width: 100%;
  background: rgb(18 18 18);
  padding: 5px 10px 5px 10px;
  color: white;
  font-size: 14px;
}
.inputbox:focus {
  border: 3px solid #747474;
}
.env_inputbox {
  border: 1px solid #747474;
  border-radius: 4px;
  width: 60%;
  background: rgb(18 18 18);
  padding: 5px 10px 5px 10px;
  color: white;
  font-size: 14px;
  margin-right: 5px;
}
.env_inputbox:focus {
  border: 3px solid #747474;
}
.mode_button {
  border-bottom: 1px solid #4A4A4A !important;
  border-left: 1px solid #4A4A4A !important;
  border-left: 1px solid #4A4A4A !important;
  border-right: 1px solid #4A4A4A !important;
  border-bottom: 1px solid #1e1e1e !important; 
  color: white !important;
  opacity: 50%!important;
}
.no_mode_button {
  border-bottom: none!important;
  border-left: none!important;
  border-right: none!important;
  border-bottom: none;
  color: white !important;
  opacity: 100%!important;
}
.input_svg{
  position: relative;
  right: -24rem;
  top: -4rem;
  width: 35px;
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  align-items: center;
  height: 46px;
}
.input_svg svg{
  position:absolute;
  right: 1rem;
  top: 52%;
  transform: translateY(-50%);
}
.addNew_container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 370px;
}
.add_button{
  color: rgba(179, 170, 255, 1);
  border: 1px solid #747474;
  border-radius: 4px;
  background-color: rgb(18 18 18);
  padding: 5px 10px 5px 10px;
  width: 100%;
  font-size: 14px;
}
.remove_repo_button{
  color: #ff7575;
  border: 1px solid #747474;
  border-radius: 4px;
  background-color: rgb(18 18 18);
  padding: 5px 10px 5px 10px;
  width: 100%;
  font-size: 14px;
}
.remove_button{
  color: #ff7575;
  border: 1px solid #747474;
  border-radius: 4px;
  background-color: rgb(18 18 18);
  width: 35%;
  font-size: 14px;
  border-radius: 4px;
}
.connect_svg{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  cursor: pointer;
}
.instructions_container{
  background: rgba(56, 56, 56, 1);
  border-radius: 8px;
  font-size: 12px;
}
.instructions_content{
  font-size: 12px;
}
.add_new_main_div{
  display: flex;
   flex-direction: column;
    gap: 1rem; 
}

.synced_list {
  list-style: none;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.syncedUrls {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.url {
  font-weight: bold;
  margin-bottom: 5px;
}

.containerDetails {
  padding-left: 10px;
  margin-top: 5px;
}

.containerItem {
  margin-bottom: 3px;
}
.syncedUrls{
  position:relative;
}
.dropdown {
  border-radius: 4px;
  padding: 5px 10px 5px 10px;
  background: rgb(18 18 18);
  color: grey;
  font-size: 14px;
  border: 1px solid #747474;
}
.dropdown:focus {
  border-color: #747474;
  outline: none;
}

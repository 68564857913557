.all-btn-wrapper {
  border: 1px solid #2c2c2c;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2c2c2c;
}

.all-btn-wrapper .group-btn {
  height: 100%;
  text-decoration: none;
  color: #fff;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.4rem 1.5rem;
  border-radius: 4px;
  background: #2c2c2c;
}

.all-btn-wrapper .group-btn:first-child {
    box-shadow: 2px 0px 4px 0px #101010;
    z-index: 999;
}

.group-btn.active {
  border-left: none !important;
  border: 1px solid #5a46ff;
  border-radius: 4px;
  background: #5a46ff;
}

.graph-name {
  font-size: 14px;
  font-weight: 400;
  width: 10em;
  letter-spacing: 0.14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Work Sans', sans-serif;
}

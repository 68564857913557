.canvas__main-div {
  height: 78vh;
  overflow: auto;
  /* background:rgb(245, 241, 241); */
  background: rgb(36, 36, 36);
  color: black;
  width: 98%;
  margin: 0 auto;
  margin-bottom: 1rem;
  display: flex;
  align-items: stretch;
  position: relative;
  /* center the container horizontally */
}

.adjacent-component {
  height: 92%;
  flex-grow: 1;
}

.sidebar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  /* align-items: start; */
  align-items: flex-start;
  padding: 0 !important;
  gap:1rem;
  /* margin-top: 2rem; */
}
.progressbar {
  position: relative;
  width: 700px;
  height: 100%;
  background-color: white;
  padding: 10px;
}

.close-button {
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 12px;
  border: none;
  background: none;
  cursor: pointer;
}

.CloseIconImage{
  width: 14px;
  height: 14px;
}

.title {
  color: white;
}

.canvashome {
  padding-left: 180px;
}

.canvashome p,
.canvashome ul {
  margin: 0;
  padding: 0;
  text-align: left;
  list-style-position: inside;
  color: white;
}

.icon-list {
  list-style-type: none;
  padding: 0;
  font-size: 1.2rem;
}

.icon-list li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.icon-list .icon {
  margin-right: 16px;
  margin-left: 10px;
}

.clould-tab {
  display: inline-block;
  border: 1px solid black;
  border-color: #aaa;
  color: black;
  background-color: white;
  margin-right: 1px;
  padding: 5px;
  border-radius: 0px 0px 14px 14px;
  cursor: pointer;
}

.cloud-list {
  margin: 0 0 10px;
  z-index: 100;
  position: absolute;
  right: 3rem;
}

.cloud-list > .react-tabs__tab--selected {
  background-color: #3f9243;
  border-radius: 0px 0px 14px 14px !important;
  color: white;
  border: none;
}
.popover .popover-arrow::after, .popover .popover-arrow::before {
  border-width:0 !important ;
}
.popover-body{
  padding: 0px !important;
  color: #fff !important;
  /* border-radius: '10px !important'; */
  /* opacity: .8; */
  /* color: white !important; */
  
}
/* .react-flow__node{
  width: 100px;
} */
/* .react-flow__node.selected{
  border: 1px solid yellow;
  
} */
.canvas-button{
  background: white;
  margin: 2px;
  padding: 6px;
  border-radius: 12px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:60px;
  height:50px
}

.canvas-button-disable{
  color: #666;
}

.button-hover:hover{
  background: #ebebe0;
}
.button-hover-icon{
  background: white;
  display: flex;
  border-radius: 12px;
  cursor:pointer;
  display:flex;
  justify-content:center;
  align-items:center;
}
.button-hover-icon div{
  background: white;
  margin: 4px;
  padding: 4px;
  border-radius: 12px;
  display:flex;
  justify-content:center;
  align-items:center;
}
.button-hover-icon:hover{
  background: #ebebe0;
}

.hover-btn-container{
  position: absolute;
  left: 5%;
  bottom: 3%;
}
.hover-btn-modal{
  position: absolute;
  left: -16%;
  bottom: -17.5%;
  display:flex;
  justify-content:center;
  align-items:center
}
.hover-btn-modal-container{
  border: 1px solid white;
  background: #595959;
  display: flex;
  border-radius: 12px;
}
.hovered-image{
  width: 20px;
  height: 20px;
}

.canvas-default-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 80%;
  max-width: 830px;
  border-radius: 25px;
  border: 4px solid #000;
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.canvas-default-modal-text {
  color: #000;
  text-align: center;
  font-family: 'DM Sans', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  padding: 100px;
}

.canvas-default-modal-divider {
  border: none;
  margin: 10px 0;
}
.parent-div {
  z-index: -2;
  position: absolute;
  width: 125px;
  height: 123px;
  border: 1px solid rgb(78, 78, 78);
  top: 9px;
  left: 9px;
  border-radius: 10px;
}
 .nodeModalClass{
  display: flex;
  flex-direction: column;
  align-items:start;
  position: absolute;
  color: white; 
  width: 180px;
  height: 116px;
  background: #181818;
  border: 1px solid rgb(78, 78, 78);
  border-radius: 6px;
}
.node-edge-modal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: white;
  width: 280px;
  height: 380px;
  background: #181818;
  text-align: left;
  padding: 16px;
  border-radius: 12px;
}

.matched-node{
  border: 2px solid rgb(0, 178, 217);
  border-radius: 8px;
}

.newNodeStyle {
  position: absolute; 
  width: 120px;
  height: 110px;
  border-radius: 8px;
  border: 1px solid #5A46FF; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #181818;
  padding-top: 40px;
  padding-bottom: 8px;
  top: 23%;
  left: 20%;
}
.inputStyle {
  background-color: #181818;
   border: none;
   outline: none;
   color: white; 
   text-align: center;
} 
.newNodeIcon1 {
  width: 15%;
  position: absolute;
  top: 110px;
  left: 40px;
}
.newNodeIcon2 {
  width: 15%; 
  position: absolute;
  top: 110px;
  left: 65px;
}

.popOverDiv {
  display: flex;
   width: 208px;
   height: 80px;
   align-items: center; 
   justify-content: center;
   background: rgb(47 45 45); 
   border-radius: 5px;
}

.popOverInput {
  width: 200px;
  height: 40px;
  outline:  none;
  background-color: rgb(47 45 45);
  border: none;
  color: white;
}
.popover .arrow {
  background: transparent !important;
  z-index: 99;
  width: 0; 
  height: 0; 
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #c7c7c7;
  bottom: -7px;
}
/* left: 90% !important; */
  /* border-top: 7px solid #c7c7c7; */
.popover-arrow{
  background: transparent !important;
   
  z-index: 99;
  width: 0; 
  height: 0; 
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;

  border-bottom: 7px solid rgb(47 45 45);

}
.add-node-edge:hover{
  background-color: #595959;
  border-radius: 2px;
  text-decoration: none;
}
.add-node-edge{
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 7px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.input-box {
  background-color: #181818;
  border: 2px solid gray;
  border-radius: 6px;
  padding: 2px;
  color: white;
}
.input-box:focus {
  border: 2px solid gray;
  border-radius: 6px;
}
.key-value {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px;
}
.del-btn {
  margin-top: 6px;
  padding-top: 3px;
  border: 1px solid gray;
  background-color: crimson;
  border-radius: 4px;
  padding: 2px 5rem 2px 5rem;
  cursor: pointer;
}
.ask-ai-btn{
  margin-top: 10px;
  width: 218px;
  border: 1px solid gray;
  background: linear-gradient(90deg, #1b05f9 0%, #f60 100%);
  padding: 2px 5rem 2px 5rem;
  border-radius: 4px;
}
.sb-btn {
  margin-top: 12px;
  border: 1px solid gray;
  background-color: #595959;
  padding: 2px 5rem 2px 5rem;
  border-radius: 4px;
}
.search-container {
  position: absolute;
  width: 305px;
  right: 15px;
  top: 30px;
  background: rgb(36, 36, 36);
  height: 45px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
}

.search-img{
  width: 24px;
  margin-left: 18px;
}

.clear-img {
  cursor: pointer;
}

.search-input-box {
  background: rgb(36, 36, 36);
  color: rgba(102, 102, 106, 1);
  width: 180px;
  border: none;
  outline: none;
}
.search-input-box::-webkit-search-cancel-button {
  display: none;
}
.search-code-view-results {
  position: absolute;
  background-color: rgb(36, 36, 36);
  z-index:999;
  width: 305px;
  max-height: 500px;
  overflow-y: auto;
  top: 107%;
  right: 0px;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  color: rgba(102, 102, 106, 1);
  text-align: left;
  border-radius: 8px;
  scroll-behavior: smooth
}
.search-code-view-results::-webkit-scrollbar {
  display: none;
}
.search-code-view-result-item {
  overflow-wrap: anywhere;
  display: flex;
  gap: 6px;
  padding: 12px;
  cursor: pointer;
}
.search-code-view-result-item:hover {
  background-color: #6a6970;
  color: white;
  border-radius: 8px;
}
.focused{
  background-color: #6a6970;
  color: white;
  border-radius: 8px;
}
.search-btn{
  height: 45px;
  width: 48px;
  position: absolute;
  top: 30px;
  right: 15px;
  align-content: center;
  border-radius: 6px;
  border: 1px solid rgba(78, 78, 78);
  background:#000;
  cursor: pointer;
}
.search-btn:hover{
  background: none;
  background:#000;
  border: 1px solid rgba(78, 78, 78);
}
.search-icon{
  width: 24px;
}
.vpcs-expansion{
    border: none;
    border-radius: 16px;
    background: rgba(24, 25, 38, 1);
}
.vpc-details{
    border: 1px solid rgba(115, 66, 221, 1);
    height: 64px;
    border-radius: 16px;
    padding-top: 17px;
    background-color:rgba(24, 25, 38, 1);
    color: rgba(102, 102, 106, 1);
}
.body-data{
    font-weight: 500;
    font-size: 16px;
    color: rgba(138, 140, 145, 1);
}
.vpc-btn{
    display: flex;
    gap: 10px;
    color: rgba(102, 102, 106, 1);
}
.subnet-btn{
    display: flex;
    gap: 4px;
    color: rgba(102, 102, 106, 1);
}
.vpcs-name{
    color: rgba(159, 161, 166, 1);
}
.uparrow{
    transform: rotate(180deg);
}
.vector-arrow{
    transform: rotate(270deg);
}
.arrow-up{
    transform: rotate(180deg);
}
.vpcs-body{
    background-color:rgba(24, 25, 38, 1);
    color: rgba(102, 102, 106, 1);
    border: 1px solid rgba(115, 66, 221, 1);
    border-top: 1px dashed rgba(115, 66, 221, 1);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 0%;
}
.vpc-subnet-instances{
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    padding: 10px;
    color: rgba(138, 140, 145, 1);
    cursor: pointer;
}
.vpc-subnet{
    display: flex;
    justify-content: space-between;
}
.subnets-data{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-top: 1px dashed rgba(115, 66, 221, 1);
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgba(24, 25, 38, 1);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
.subnet-expansion{
    margin-bottom: 10px;
    border: none;
    background-color: rgba(24, 25, 38, 1);
    width: 280px;
}
.category-compute-data{
    background-color: rgba(24, 25, 38, 1);
    border-bottom: 1px solid rgba(40, 40, 40, 1);
    border-radius: 0;
}
.storage-data{
    background-color: rgba(24, 25, 38, 1);
    border-bottom: 1px solid rgba(40, 40, 40, 1);
    border-radius: 0;
    border-end-end-radius: 16px;
    border-end-start-radius: 16px;
}
.category-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(24, 25, 38, 1);
    height: 54px;
}
.category-btn{
    display: flex;
    gap: 10px;
    color: rgba(163, 163, 163, 1);
    text-align: left;
}
.vpc-category{
    display: flex;
    /* gap: 110px; */
    justify-content: space-between;
}
.vpc-category-count{
    display: flex;
    gap: 10px;
}
.categories-count{
    padding: 3px 9px;
    background-color: rgba(45, 46, 71, 1);
    border-radius: 50%;
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    font-size: 12px;
    opacity: 0.8;
}
.subnet-header{
    border: 1px dashed rgba(28, 198, 0, 1);
    height: 64px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: rgba(24, 25, 38, 1);
    border-radius: 16px;
}
.subnet-body{
    background: rgba(24, 25, 38, 1);
    color: rgba(102, 102, 106, 1);
    border-bottom: 1px dashed rgba(28, 198, 0, 1);
    border-left: 1px dashed rgba(28, 198, 0, 1);
    border-right: 1px dashed rgba(28, 198, 0, 1);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 0%;
}
.subnet-count{
    background-color:rgba(45, 46, 71, 1);
    color:rgba(255, 255, 255, 1);
    font-weight: 700;
    font-size: 13px;
    opacity: 0.8;
    padding: 4px 8px 4px 8px;
    border-radius:32px;
    margin-right: 8px;
}
.no-resource{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
    font-size: 12px;
}
.no-border-bottom {
    border-bottom: none;
    border-bottom: 1px solid rgba(40, 40, 40, 1);
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
}
.category-body{
    background-color: rgba(7, 8, 15, 1);
    color: rgba(138, 140, 145, 1);
}
.category-sub-body{
    background-color: rgba(7, 8, 15, 1);
    color: rgba(138, 140, 145, 1);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
.category-details{
    background-color: rgba(7, 8, 15, 1);
}
.category-details-header{
    display: flex;
    justify-content: space-between;
    background-color: rgba(7, 8, 15, 1);
}
.category-details-header:hover{
    display: flex;
    justify-content: space-between;
    background-color: rgba(18, 20, 31, 1);
    border-radius: 10px;
}
.category-details-vpc-header{
    display: flex;
    justify-content: space-between;
    background-color: rgba(7, 8, 15, 1);
}
.category-details-vpc-header:hover{
    display: flex;
    justify-content: space-between;
    background-color: rgba(18, 20, 31, 1);
    border-radius: 10px;
}
.category-details-body{
    background-color: rgba(7, 8, 15, 1);
    color: rgba(138, 140, 145, 1);
    border-left: 1px solid rgba(68, 68, 71, 0.4);
    margin-left: 25px;
}
.compute-btn{
    display: flex;
    gap: 10px;
    color: rgba(163, 163, 163, 1);
}
.matched-text {
    color: white !important;
    font-weight: 800 !important;
}
.container {
  text-align: center;
  padding: 20px;
}

.product-title {
  font-weight: 400;
  font-size: 72px;
  font-family: 'Work Sans', sans-serif;
  margin: 0;
}

.title-strong {
  font-weight: 700;
  font-size: 72px;
  font-family: 'Work Sans', sans-serif;
  color: #5A46FF;
  margin: 0;
}

.image-container {
  position: relative;
  margin-top: 7px;
}

.image {
  max-width: 100%;
  height: auto;
  border-radius: 16px;
}

.image-frame {
  position: absolute;
  left: 8%;
  top: 53%;
  width: 391.36px;
  height: 112px;
}

.description-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.description {
  font-weight: 400;
  font-size: 24px;
  font-family: 'Work Sans', sans-serif;
  line-height: 33.6px;
  max-width: 700px;
}

.codeView-img{
  padding-right: 7rem;
}
.code-view-img {
  border-radius: 32px;
}

.join-btn {
  /* padding: 15px 30px; Increase padding for a bigger button */
  font-size: 18px; /* Increase font size */
  border-radius: 5px; /* Optional: Add some border radius */
  width: 300px; /* Optional: Set a width */
  white-space: wrap; /* Optional: Wrap text */
}

@media screen and (max-width: 1200px) {
  .product-title, .title-strong {
    font-size: 72px;
  }
  .description {
    font-size: 20px;
    line-height: 30px;
    padding: 0 20px;
  }
  .image-frame {
    left: 30px;
    top: 125%;
    width: 300px;
    height: 90px;
  }
  .codeView-img{
    padding-right: 7rem;
  }
}

@media screen and (max-width: 768px) {
  .product-title, .title-strong {
    font-size: 56px;
  }
  .description {
    font-size: 18px;
    line-height: 28px;
    max-width: 90%;
    padding: 0 10px;
  }
  .image-frame {
    left: 15px;
    top: 120%;
    width: 250px;
    height: 75px;
  }
}

@media screen and (max-width: 480px) {
  .product-title, .title-strong {
    font-size: 36px;
  }
  .description {
    font-size: 16px;
    line-height: 24px;
    max-width: 100%;
    padding: 0 5px;
  }
  .image-frame {
    left: -47px;
    top: 53%;
    width: 200px;
    height: 60px;
  }
  .code-view-img {
    width: 360px;
    border-radius: 12px;
  }
}

.usecase-section {
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.inner-container {
  display: flex;
  padding-top: 30px;
  justify-content: center;
  align-items: center;
  margin: 0px 80px;
  padding-bottom: 40px;
  position: relative;
}

.use-cases {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.use-cases .usecase-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.use-cases .usecase-title span {
  font-style: 'Work Sons';
  font-size: 48px;
  font-weight: bold;
}

.use-cases .highlighted-img {
  padding-top: 40px;
  width: 1110px;
  height: 841px;
}

.refresh-icon {
  position: absolute;
  right: 0;
}

@media (max-width: 1024px) {
  .inner-container {
    flex-direction: column;
    margin: 0 20px;
    padding-bottom: 20px;
  }

  .use-cases {
    margin-left: 0;
    margin-top: 20px;
  }

  .use-cases .usecase-title span {
    font-size: 36px;
  }

  .use-cases .highlighted-img {
    width: 100%;
    height: auto;
  }

  .refresh-icon {
    bottom: 0;
    right: 50%;

  }
}

@media (max-width: 480px) {
  .use-cases .usecase-title span {
    font-size: 28px;
  }

  .use-cases .highlighted-img {
    width: 100%;
    height: auto;
  }
  .inner-container {
    padding-top: 15px;
  }
}

  /* last section */

.key-benefits-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  padding-top: 60px;
}

.key-benefits-heading {
  text-align: center;
  margin-bottom: 40px;
}

.key-benefits-heading strong {
  font-size: 48px;
}

.key-benefits-row {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 40px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.key-benefit-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  height: 327px;
  max-width: 350px;
  padding: 20px;
  border-radius: 32px;
  box-sizing: border-box;
}

.key-benefit-card img {
  width: 80px;
  height: 80px;
}

.key-benefit-card span {
  text-align: left;
  font-size: 16px;
  margin-top: 10px;
}

.key-benefit-card strong {
  font-size: 18px;
  margin-top: 10px;
}

.key-benefit-card p {
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
}

.card-sync {
  background: #ECFFD8;
}

.card-security {
  background: #E8E6FF;
}

.card-ai {
  background: #FFDAF9;
}

.card-comprehensive {
  background: #FCF3E2;
}

@media (max-width: 1024px) {
  .key-benefits-heading strong {
    font-size: 42px;
  }

  .key-benefit-card {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .key-benefits-heading strong {
    font-size: 36px;
  }

  .key-benefits-row {
    gap: 15px;
  }

  .key-benefit-card {
    max-width: 48%;
  }
}

@media (max-width: 480px) {
  .key-benefits-heading strong {
    font-size: 28px;
  }

  .key-benefits-container {
    margin: 0 10px;
  }

  .key-benefits-row {
    gap: 10px;
    flex-direction: column;
  }

  .key-benefit-card {
    max-width: 100%;
  }

  .key-benefit-card img {
    width: 80px;
  }
}

.beta-button {
    color: white;
    width: 120px;
    border-radius: 6px;
    font-size: 16px;
    height: 37px;
    font-family: 'Work Sans', sans-serif;
    border: 1px solid #3d3d3d;
    align-items: center;
    justify-content: center;
  }
  
  .beta-button:hover {
    border: none;
    background-color: #5a46ff;
  }

  .beta-btn-img{
    filter: invert(1);
    opacity: 0.9;
    padding-top: 12px;
    transform: scale(1.2);
    /* padding-right: 3px; */
  }
ul.project-snapshot-tab {
  border: none;
}

.activeTab {
  background-color: red !important;
}

ul.project-snapshot-tab .tab {
  background: transparent !important;
  border: none;
  padding: 4px 0px;
  margin-right: 25px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff !important;
    
}

ul.project-snapshot-tab .tab.active-tab {
  border-bottom: 2px solid #ffffff !important;
  opacity: 1;
}

ul.project-snapshot-tab .inactive-tab {
}

.group-wrapper {
  padding: 25px 0px !important;
  color: #ffffff;
}

.group-card {
  /* border:1px solid black; */
  text-align: left;
  margin-bottom: 1rem;
  position: relative;
}

.group-card img {
  margin-bottom: 1em;
  width: 100%;
}

.group-card h6 {
  margin-bottom: 0px;
  font-weight: 700;
}

.group-card p {
  font-size: 14px;
  font-weight: 400;
}

.custom-label {
  opacity: 0.5;
}

.error-message{
  color: var(--colors-red-500, #F2323F);
  font-family: 'inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  margin-top: -0.8rem;
  margin-bottom: 1rem;
}

.custom-input {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.20) !important;
}

.custom-textarea { 
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.20) !important;
  box-shadow: none;
  color: #ffffff !important;
}

.custom-textarea::placeholder {
  color: #ffffff;
  opacity: 0.5;
}

.custom-textarea:focus {
  background-color: transparent;
  box-shadow: none;
  border: 1px solid #ffffff !important;
} 

.time-ago-wrapper {
  /* text-align: right; */
  font-size: 0.8rem;
  opacity: 0.5;
}

.project_des {
  background-color: black;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #ffffff;
  position: absolute;
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: 2px 2px 3px #aaaaaa;
  z-index: 9999;
  font-size: 14px;
  border: 1px solid #dfd1d1;
  top: 22%;
  animation: 1s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
.copiedBtn {
  color: gray;
  cursor: not-allowed;
}
.correct-img {
  width: 15px;
  margin-right: 5px;
  cursor: not-allowed;
}
.copy-img {
  width: 15px;
  margin-right: 5px;
  filter: invert(1);
}
.project-name {
  background-color: #161616;
  color: white;
  border: 2px solid gray;
  border-radius: 6px;
  padding-left: 8px;
}
.Toastify_show {
   display: block !important;
}

.Toastify__toast-container--top-right {
    top: 5rem;
    right: 8rem;
}

.Toastify__toast-container {
    z-index: 9999;
    position: fixed;
    box-sizing: border-box;
    color: #fff;
    min-width: 440px;
    display: none;
}

.Toastify__toast {
    position: relative;
    box-sizing: border-box;
    padding: 0.75rem 0.5rem;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    cursor: default;
    direction: ltr;
    z-index: 0;
    background: #F8F9FA;
    border: 1px solid #D0D0D0;
    width: auto;
    max-width: 90%;
    word-wrap: break-word;
}

.Toastify__toast.success {
    background: #DCFAE0;
    border: 1px solid #8AE896;
}


.Toastify__toast.error {
    background: #FCDCDD;
    border: 1px solid #FC9C9E;

}

.Toastify__toast-body {
    color: #404145;
    margin: auto 0;
    flex: 1 1 auto;
    padding: 5px;
    width: 450px;
    max-width: 100%;
    display: flex;
    align-items: flex-start;
    font-size: 0.875rem;
    font-family: 'Work Sans', monospace;
    font-weight: 600;
    line-height: 18px;
    gap: 0.5rem;
    text-align: left;
    word-break: break-word;
    white-space: normal;
    overflow-wrap: break-word;
}

.Toastify__toast.success .Toastify__toast-body {
    color: #3DBA4E;
}

.Toastify__toast.error .Toastify__toast-body {
    color: #F2323F;
}


.Toastify__close-button {
    width: 16px;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s ease;
    align-self: flex-start;
}

.Toastify__close-button svg {
    fill: #7E8085;
}

.Toastify__toast.success .Toastify__close-button svg {
    fill: #3DBA4E;
}

.Toastify__toast.error .Toastify__close-button svg {
    fill: #F2323F;
}
